import React from "react";
import { useGlobalContext } from "../../../lib/context/GlobalContext/GlobalContext";

function PrivacyPolicy() {
  const { closeSubmenu } = useGlobalContext();
  return (
    <div>
      <div className="container" onMouseMove={closeSubmenu}>
        <h4>TERMS OF USE</h4>

        <h5>ACCEPTANCE OF THE TERMS</h5>
        <div>
          <p>
            These Terms of Use (the “Terms”) constitute a binding and
            enforceable legal contract between <b>bantumart</b> Online Limited
            (together, the “Administrator”, “we”, “us”) and you. Please read
            these Terms carefully.
          </p>

          <p>
            1:Your access and use of the bantumart website and mobile
            applications, as well as any service, content, and data available
            via them (together, the “Service” or the “Platform”) are governed by
            these Terms.
          </p>
          <p>
            2:If you do not agree with any part of these Terms, or if you are
            not eligible or authorized to be bound by the Terms, then do not
            access or use the Service.
          </p>
          <p>
            3:Please also review our Privacy Policy . The terms of the Privacy
            Policy and other supplemental terms, rules, policies, or documents
            that may be posted on the Platform from time to time are hereby
            expressly incorporated herein by reference. We reserve the right, in
            our sole discretion, to make changes or modifications to these Terms
            at any time and for any reason with or without prior notice.
          </p>

          <h5>MPORTANT DISCLAIMERS</h5>
          <p>
            1:ALL SERVICES RENDERED BY THE ADMINISTRATOR ARE RENDERED “AS IS”,
            “AS AVAILABLE” AND “WITH ALL FAULTS”, AND THE ADMINISTRATOR
            DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING WITHOUT
            LIMITATION ANY GUARANTIES REGARDING CONDITION, QUALITY, LIFE
            DURATION, PERFORMANCE, ACCURACY, RELIABILITY, COMMERCIAL VALUE AND
            SUITABILITY FOR SPECIFIC PURPOSES. ALL SUCH WARRANTIES AND
            LIABILITIES ARE HEREBY EXCLUDED.
          </p>
          <p>
            2:ADMINISTRATOR HAS NO CONTROL OVER AND DOES NOT GUARANTEE THE
            EXISTENCE, QUALITY, SAFETY OR LEGALITY OF GOODS AND SERVICES
            PUBLISHED BY USERS ON THE PLATFORM; THE TRUSTWORTHINESS OR ACCURACY
            OF INFORMATION PROVIDED BY USERS IN THE ANNOUNCEMENTS; THE ABILITY
            OF SELLERS TO SELL GOODS OR TO PROVIDE SERVICES; THE ABILITY OF
            BUYERS TO PAY FOR GOODS OR SERVICES; OR THAT A USER WILL ACTUALLY
            COMPLETE A TRANSACTION. ADMINISTRATOR MAKES NO GUARANTEES CONCERNING
            THAT MANUFACTURING, IMPORT, EXPORT, OFFER, DISPLAYING, PURCHASE,
            SALE, ADVERTISING AND/OR USE OF PRODUCTS OR SERVICES, WHICH ARE
            OFFERED OR DISPLAYED ON THE PLATFORM DO NOT INFRINGE ANY THIRD
            PARTIES’ RIGHTS. THEREFORE, ADMINISTRATOR EXPRESSLY DISCLAIMS ANY
            LIABILITY IN CONNECTION TO MATERIALS AND INFORMATION POSTED BY USERS
            ON THE PLATFORM.
          </p>
          <p>
            3:YOU ARE ENCOURAGED TO CHECK THE GOODS BEFORE PAYMENT AND TO
            REQUEST THE SELLER TO PROVIDE DOCUMENTS CONFIRMING COMPLIANCE OF THE
            GOODS WITH APPLICABLE REQUIREMENTS OF LAWS, REGULATIONS, RULES,
            GUIDELINES, STANDARDS.
          </p>
          <p>
            4:YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR SAFETY
            AND YOU UNDERSTAND THAT YOU SHOULD MEET WITH OTHER INDIVIDUALS FOR
            COMPLETION OF A TRANSACTION ONLY IN SAFE PUBLIC PLACES IN DAYLIGHT
            HOURS. YOU ARE SOLELY RESPONSIBLE FOR CONDUCTING DUE DILIGENCE OF
            ANY INDIVIDUAL OR ORGANIZATION REQUESTING A MEETING TO CONDUCT A JOB
            INTERVIEW OR TO COMPLETE A TRANSACTION. ADMINISTRATOR DISCLAIMS ANY
            RESPONSIBILITY FOR USER’S INTERACTION WITH ANY INDIVIDUAL OR
            ORGANIZATION.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
