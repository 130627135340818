export const JobList = [
  {
    id: "mobile-app-development",
    title: "Mobile Application Developer",
    location: "Remote",
    category: "Technical",
    type: "Contract",
    posted: "13 day ago",
    openings: "9",
    exprience: "3-5 years",
    description:
      " Mobile App Developer, you will work with a pool of exceptionally talented Junglee engineers and design and build next generation applications for Android and iOS devices. You will collaborate with cross-functional teams to define, design, and ship new features and unit-testing code for robustness, including edge cases, usability, and general reliability. You should have a passion for making the best use of mobile technologies and building advanced, ultimate applications.",
    responsibities:
      " Able to develop performance optimised, smooth functioning hybrid mobile apps for both Android & iOS. Development closures within the specified deadlines, debugging skills is must. Able to work on existing and development from scratch mobile app source codes. Developer friendly & clean code writing is required. Deployment to android play store & app store with the developer guidelines is an added bonus.",
    lookingFor:
      " React Native - Expo framework, PHP/Php LaravelFramework, REST API's Integration & Development, MySQL Database Management, Php myadmin.Mobile App Developers specialising in React-Native, Php Laravel, REST API's, MySQL DB, Third party API's integration. Able to develop hybrid apps compatible on both Android & iOS.",
    skills:
      "React Native, PHP, Laravel, REST API's, MySQL DB, Third party API's integration",
  },

  {
    id: "junior_social_media_manager",
    title: "Junior Social Media Manager",
    location: "Remote",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    Details: "https://www.google.com/",
    openings: "1",

    exprience: "2 years",
    description:
      "Job summary In today's fast moving, social first media landscape, here's an opportunity to help Ug365 tell its unique story in Uganda. This is a high visibility role, embedded within the PR team, working closely across all Ug365 businesses in the country and with senior leadership to reach a diverse audience of customers, policymakers, partners, and employees in real time. Ug365 is seeking a self-motivated, high judgement, highly organised Social Media Manager for our Ug365 News Uganda channels.The successful candidate will be a passionate and creative self-starter eager to engage on new projects and tell Ug365's Uganda story through smart and engaging content. A strong understanding of the current social media landscape is a must. Working with corporate and consumer communications teams, the individual will develop strong content strategy with a distinct and credible Ug365 voice and robust amplification plans to achieve measurable results. They will execute social campaigns work across internal teams including brand, creative, synergy, awards, events and PR, as well as partner with external social strategy and creative agencies. They will collaborate closely with the paid social team to inform and implement holistic strategies. The position will report in to the Owned Channels & Social Lead for Ug365 Uganda. The successful candidate will have a proven track record delivering results on social campaigns, with the ability to develop strategy from scratch, sell big ideas internally and externally, multi-task and prioritize, manage a high-volume of campaigns by being nimble and creative, plus a drive for testing, learning, and making insight- and data-driven decisions.   .",
    responsibities:
      "Developing, implementing, and managing social media strategy across platforms like Twitter, Instagram, Facebook, and YouTube, alongside building LinkedIN outreach . The individual will lead on all aspects of social media in Uganda - social strategy development in alignment with our global reputational, brand, and business goals campaign, content, and channel management and measurement and evaluation. For this, they will be working closely with Uganda PR teams, the APAC social team, as well as global social teams, to coordinate local delivery of social campaigns. The individual will lead our social media strategy and campaign development across channels like Twitter, Instagram, Facebook, and YouTube, while supporting communication and amplification efforts to increase our businesses awareness and perception.Collaborating closely with cross-functional teams to produce impactful social-first campaigns, as well as providing direction to and managing external creative agency and production partners . Writing, producing, commissioning, and publishing timely content across a range of mediums, including video . Coming up with content ideas that are relevant for Ug365 and interesting for customers . Using social media to drive traffic to key stories on the About Ug365 blog . Measuring the success of every social campaign with a deep understanding of social media metrics and data analysis, balanced with driving new audience growth . Working with global social teams to define local measurement and ROI for content and support in evolving our content brand guidelines . Developing the amplification strategy across earned, paid, and owned media . Evolving channel admin and reporting processes to ensure a slick and cohesive operation . Utilising industry and customer insights to inform strategies and be an expert on social trends and new and emerging platforms . Managing the efficient deployment of budget aligned to overall goals Basic Qualifications : ",
    lookingFor:
      "8+ years work experience developing and evaluating social media brand strategies . Experience using data and metrics to measure impact and determine improvements . Experience using Microsoft Excel to manipulate and analyze data . Experience building, executing, and scaling cross-functional marketing programs . Excellent written and verbal communications skills . Bachelor's degree Preferred Qualifications : . A mix of agency and in-house experience is preferred, including social media communications, brand marketing, and digital advertising . Experience overseeing editorial operations for major brands or in a media outlet . A proven track record of creating original content that offers something unique to digital audiences building relevant and highly engaged communities . Experience working collaboratively with multiple stakeholders, demonstrating a genuine openness to feedback and change while resisting the negative effects of editing by committee . Experience driving a multi-channel editorial strategy, working with cross-functional teams, and managing external partners with a demonstrated knowledge of what works for a brand across social media . Experience using customer data/insights to inform campaign strategy and planning . Experience of thinking, writing, communicating, and producing with clarity for a variety of audiences . Experience developing, managing, and analyzing content amplification strategies . Experience in advertising operations, including tools like Sprinklr or native ad management tools . Experience in managing agencies and budgets . Highly organised - able to juggle multiple projects and tasks simultaneously while managing essential admin and reporting work . Strong leader - able to inspire agencies to deliver and demonstrate responsibility to senior managers",
  },
  {
    id: "UX_UI_designer",
    title: "UX/UI designer",
    location: "Kampala",
    category: "Techno-functional",
    openings: "6",
    type: "Contract",
    posted: "5 day ago",
    exprience: "1.2 years and above",
    description:
      "As a UX/UI Designer, you'll be responsible for end-to-end design of multiple web, mobile & tablet apps for different stakeholders like admins, teachers and students. Your work will include designing new features as well updating the existing ones based on customer needs & prioritisation. You’ll also be responsible for designing various branding & marketing collaterals like icons, logos, websites, etc. as needed. Your work will also include market & competitive research along with original creative thinking to create experiences that solve problems in simple but effective ways.You will need to have a patient, collaborative, highly objective & a process driven mindset to work with the Product Manager and go past your own biases to deliver effective & simple solutions for our clients. You will also need to have constant engagement with the Engineering team to resolve any queries and ensure that the design is being translated into the product as intended. The role requires you to understand the problem being solved for the user, collect the users’ needs and translate it into effective & holistic solutions with smooth experiences. As a designer at an early stage startup where delivery speed matters a lot, it’s extremely important for you strike the right balance with minimal simple design but effective solutions. You will need a combination of creativity, business understanding, user empathy, process driven approach, collaborative mindset & the technical expertise needed to create simple, efficient and effective solutions and experiences",
    responsibities:
      "Understanding the business direction, problem being solved for clients and the solution being proposed.Empathising with users/customers to understand their needs, develop solutions that solve their problems.Conducting market and competitive research to understand best practices and commonly used controls.Create holistic design solutions that address business, brand, and user requirements.Effectively communicate conceptual ideas, design rationale, and the specifics of the user-centric design process.Collaborate with the product team and engineering team to deliver the final product.",
    lookingFor:
      "Degree in Graphic Design, Design Communication, Human-Computer Interaction or related field, or equivalent combination of education and experience.1-4 years of experience in user experience design or similar discipline.Must have a basic grasp of web design principles and a good understanding of design tools like Adobe XD, Adobe Illustrator, Adobe Photoshop, etc.",
    skills:
      "Adobe XD, Adobe Illustrator, Adobe Photoshop, Sketch, Inkscape , Figma , Framer ,Canva",
  },
  {
    id: "data_scientist",
    title: "Data Scientist",
    location: "Remote",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    Details: "https://www.google.com/",
    openings: "1",

    exprience: "2 years",
    description:
      "In this role, you'll utilize your skills in AI, ML, DL to develop registration, segmentation, classification, clustering algorithms and other problems relevant to our products. You should be able to study and implement cutting-edge research papers, perform statistical analysis and evaluation of models and develop insights and strategies to improve model performance. You will be fully-hands on in productizing your solutions - make them scalable, optimize timing performance and ensure they work in the product without issues.",
    responsibities:
      "· Develop highly scalable ML and DL models for deployment in product, including training, testing, data analysis and model improvements. Evaluate algorithms, build prototypes, perform analysis to make right choices for solutions. Develop & deploy solutions in products, working closely with other engineering teams. Develop IP, publish papers in relevant journals/conferences.",
    lookingFor:
      " B.Tech/M.Tech/M.S/PhD in Computer Science, EE, Mathematics, Statistics. At least 3 years of industry experience in relevant field. Strong understanding of fundamentals of ML, DL and Computer Vision.Fluent in using toolkits/frameworks like PyTorch, Keras, Tensorflow and others. Fluent in Python. Strong fundamentals in traditional Image Processing will be a big plus. Previous experience in Medical Image Processing will be a big plus",
    skills: "Python, PyTorch,Keras, Tensorflow,Scikit- learn, Scikit-image",
  },
  {
    id: "front_end_developer",
    title: "Front-End Developer",
    location: "Remote",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    Details: "https://www.google.com/",
    openings: "1",

    exprience: "2 years",
    description:
      "The ideal candidate will be responsible for designing, developing, testing, and debugging responsive web and mobile applications for the company. Using Frontend: Typescript, React, Redux, Native JavaScript Backend: NodeJs is a plus Infrastructure: AWS or GCP",
    responsibities:
      "Build reusable code and libraries for future use .Accurately translate user and business needs into functional frontend code",
    lookingFor:
      "Bachelor's degree or equivalent in Computer Science. 2+ years' experience in frontend development. Experience with React, Redux, NodeJS, and/or other frontend frameworks. Experience with testing and debugging. Experience with responsive web and mobile applications. Experience with AWS or GCP.",
    skills: "Typescript, React, Redux, Native JavaScript, NodeJS, AWS, GCP",
  },
  {
    id: "software_tester",
    title: "Software Tester",
    location: "Onsite",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    Details: "https://www.google.com/",
    openings: "1",

    exprience: "2 years",
    description:
      "As a Senior Test Engineer you should able to devise Test Strategy, review test plan, traceability matrix and ensure usage of tools for optimization.You should be able to ensure the availability test environment, design test and defect reporting formats and manage the team.You should update project related data as required in applicable systems and ensure timely reporting and response to stakeholders",
    responsibities:
      "You should be able to devise Test Strategy, review test plan, traceability matrix and ensure usage of tools for optimization.You should be able to ensure the availability test environment, design test and defect reporting formats and manage the team.You should update project related data as required in applicable systems and ensure timely reporting and response to stakeholders",
    lookingFor:
      "Degree in Computer Science, Engineering or related field, or equivalent combination of education and experience.1-4 years of experience in software testing or similar discipline.Must have a basic grasp of software testing principles and a good understanding of testing tools like JUnit, TestNG, Selenium, etc.",
    skills: "JUnit, TestNG, Selenium,",
  },
  {
    id: "account_assistant",
    title: "Account Assistant",
    location: "Lugazi",
    category: "Functional",
    type: "Full Time",
    posted: "6 day ago",
    openings: "1",
    description:
      "The ideal candidate will have strong organizational skills and have an ability to accurately track and record cash flow. This candidate should have experience in maintaining a database of financial information and be able to recognize and solve any problems that may arise. Lastly, where appropriate this candidate will track and create a report with related information at Month end.",
    exprience: "2 years",
    responsibities:
      " Review and record invoices from vendors to ensure accuracy in billing. Process expense reports from employees Identify discrepancies and escalate in appropriate manner.Have knowledge of approved vendors and policies to avoid paying unauthorized invoices and expenses",
    lookingFor:
      " Bachelor's degree in Accounting or related field.1- 2 years in accounting or related field.Strong organizational, analytical and recording skills. Detail oriented.Proficient in Microsoft Office suite.Good in Advance Excel with Vlookup",
    skills: "Microsoft Office, Advance Excel, Vlookup",
  },
  {
    id: "Area_Sales_Manager",
    title: "Area Sales Manager",
    location: "Kampala",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    openings: "1",
    exprience: "2 years",
    description:
      "The ideal candidate is a creative problem solver at heart who thrives in a team environment. You will help manage and oversee relationships with new and existing partners through high-touch networking, lead generation, and market research.",
    responsibities:
      "· Willing to familiarize yourself with the company's vision and mission seeking to accomplish set goals and objectives. Collecting and maintaining client information in the CRM database. Make calls to clients and respond to callback requests. Email & WhatsApp conversations with potential leads. Make potential leads understand our courses. Converting potential leads to attend the trail Classes.Learning & using our customer relations management software & others related computer software's.",
    lookingFor:
      "Excellent written and verbal communication skills.Ability to work under pressure. Laptop is mandatory. Willing to work in a startup environment (fast paced). Willing to work 6 days..",
    skills:
      "CRM, Marketing, Sales, Customer Service, Customer Relationship Management",
  },
  {
    id: "Receptionist",
    title: "Receptionist",
    location: "Lugazi",
    category: "Functional",
    type: "Full Time",
    posted: "1 day ago",
    openings: "1",
    exprience: "2 years",
    description:
      "We are looking for a receptionist cum sales coordinator to be responsible for greeting clients and visitors to our office. You will be in charge of giving clients directions to various parts of the office, contacting employees regarding visitors, answering phones and taking messages, and sorting and distributing mail. To be successful in this role, you will need excellent written and verbal communication skills, as well as competency in Microsoft Office applications such as Word and Excel. Prior experience as a receptionist is also helpful.",
    responsibities:
      " Greet clients and visitors with a positive, helpful attitude.Assisting clients in finding their way around the office.Announcing clients as necessary. Helping maintain workplace security by issuing, checking, and collecting badges as necessary and maintaining visitor logs.Assisting with a variety of administrative tasks including copying, faxing, taking notes, and making travel plans. Preparing meeting and training rooms. Answering phones in a professional manner and routing calls as necessary. Assisting colleagues with administrative tasks. Performing ad-hoc administrative duties. Answering, forwarding, and screening phone calls.Sorting and distributing mail. Provide excellent customer service.Scheduling appointments.Tracking sales leads, coordinating quotes with sales packs, assist with order processing, and assist with managing customer accounts.Support sales force on the sales reporting system.Distribute monthly, quarterly, and year-end sales volume reports.Initiate and maintain a good working relationship with the Sales team and also our internal personnel and external clients.Taking customer and vendor phone calls and responding quickly and efficiently.",
    lookingFor:
      " Associates or bachelor’s degree in a related field. Prior experience as a receptionist or in a related field.Consistent, professional dress and manner.Excellent written and verbal com",
    skills: "writing, verbal communication, Microsoft Office, Excel, Word",
  },
];
