import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./FixedBottom.css";
import { AuthContext } from "../../lib/context/AuthContext/auth";

function FixedBottom() {
  const { user } = useContext(AuthContext);
  return (
    <div className="hide-on-large-only">
      <div className="bottom_footer">
        <div className="row">
          {user ? (
            <>
              <div className="col l6 s6">
                <div
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  <span>
                    <Link
                      style={{
                        fontWeight: "700",
                      }}
                      to="/profile"
                    >
                      {" "}
                      my Ads{" "}
                    </Link>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col l4 s4">
                <div
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  <span>
                    <Link
                      style={{
                        fontWeight: "700",
                      }}
                      to="/sign_up"
                    >
                      {" "}
                      Register{" "}
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col l4 s4">
                <div
                  style={{
                    borderRight: "1px solid #e0e0e0",
                  }}
                >
                  <span>
                    <Link
                      style={{
                        fontWeight: "700",
                      }}
                      to="/sign_in"
                    >
                      Login{" "}
                    </Link>
                  </span>
                </div>
              </div>
            </>
          )}

          <div className={user ? "col l6 s6" : "col l4 s4"}>
            <div>
              <span>
                <Link
                  style={{
                    fontWeight: "700",
                  }}
                  className="white-text"
                  to="/post_ad"
                >
                  {" "}
                  + Post Ad
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FixedBottom;
