import React, { useState, useRef, useEffect } from "react";
import { useGlobalContext } from "../../../../lib/context/GlobalContext/GlobalContext";
import { Link } from "react-router-dom";

const Submenu = () => {
  const {
    isSubmenuOpen,
    page: { page, links, categoryUrl },
    location,
  } = useGlobalContext();
  const container = useRef(null);
  const [columns, setColumns] = useState("col-1");
  useEffect(() => {
    setColumns("col-1");
    const submenu = container.current;
    const { center, bottom } = location;
    submenu.style.left = `${center}px`;
    submenu.style.top = `${bottom}px`;
    //console.log(links);
    if (links.length === 3) {
      setColumns("col-2");
    }
    if (links.length > 3) {
      setColumns("col-2");
    }
  }, [page, location, links]);

  return (
    <aside
      className={`${isSubmenuOpen ? "submenu show" : "submenu"}`}
      ref={container}
    >
      <section>
        <h4>{page}</h4>
        <div className={`submenu-center ${columns}`}>
          {links.map((link, index) => {
            const { url, icon, label, img, subCategoryUrl } = link;
            return (
              <Link
                to={`/mega/${categoryUrl}/${subCategoryUrl}`}
                style={{
                  fontSize: "0.8rem",
                  margin: "0.5rem",
                }}
                key={index}
                href={url}
              >
                {icon}
                {label}
              </Link>
            );
          })}
        </div>
      </section>
    </aside>
  );
};

export default Submenu;
