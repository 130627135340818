import React, { useState, useEffect } from "react";
import NavBarTwo from "../../BusinessComponents/NavBarTwo/NavBarTwo";
import { db, auth, storage } from "../../../lib/Firebase/firebase";
import {
  getDoc,
  doc,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import "./JobApplication.css";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

function JobApplication() {
  const navigate = useNavigate();
  const { title } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [submiting, setSubmiting] = useState(false);

  const [monthsOfExperience, setMonthsOfExperience] = useState("");
  const [skills, setSkills] = useState("");

  const invalid =
    imageUpload === null || address === "" || yearsOfExperience === "";
  //   address === "" ||
  //   address === null ||

  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((docSnap) => {
      if (docSnap.exists) {
        setUser(docSnap.data());
        setLoading(false);
      }
    });
  }, []);

  const candidateSubmitHandler = async () => {
    setSubmiting(true);

    try {
      const candidateRef = await addDoc(collection(db, "Applicants"), {
        userId: auth.currentUser.uid,
        jobTitle: title,
        createdAt: serverTimestamp(),
        candidateName: user.name,
        candidateEmail: user.email,
        candidateAddress: address,
        candidatePhone: phone,
        candidateYearsOfExperience: yearsOfExperience,
        candidateMonthsOfExperience: monthsOfExperience,
        candidateSkills: skills,
      });

      const fileRef = await ref(storage, `Applicants/${candidateRef.id}`);
      const uploadTask = await uploadBytes(fileRef, imageUpload);
      const url = await getDownloadURL(ref(storage, uploadTask.ref.fullPath));
      const update = await updateDoc(doc(db, "Applicants", candidateRef.id), {
        image: url,
        imagePath: uploadTask.ref.fullPath,
      });

      if (update) {
        alert("Application Submitted Successfully");
      }
    } catch (error) {}

    console.log("candidateSubmitHandler");
    alert(` Hello  ${user.name} your  Application  Submitted Successfully
    We will contact you soon`);
    navigate("/");

    setSubmiting(false);
    // upload image to firebase storage

    setImageUpload(null);
    setAddress("");
    setPhone("");
    setYearsOfExperience("");
    setMonthsOfExperience("");
    setSkills("");
  };
  return (
    <div className="application_page">
      <NavBarTwo />

      {loading ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <div className="container  job_application_form_wrap">
            <div className="row ">
              <h2>Please Submit your profile</h2>
              <small class="right red-text ">
                Resume should be in pfd format
              </small>
              <div class="file-field input-field col l9">
                <div className="btn btn-small red">
                  <span>File</span>
                  <input
                    type="file"
                    onChange={(event) => {
                      setImageUpload(event.target.files[0]);
                    }}
                  />
                </div>
                <div class="file-path-wrapper">
                  <input
                    class="file-path validate"
                    type="text"
                    placeholder="select your resume"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col l6 s12">
                <small className="right">Job tittle</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    id="job_title"
                    type="text"
                    disabled={true}
                    value={title}
                  />
                </div>
              </div>
              <div className="col l6 s12">
                <small className="right">candidate Name</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    disabled={true}
                    value={user.name}
                    id="user_name"
                    type="text"
                  />
                </div>
              </div>
              <div className="col l6 s12">
                <small className="right">candidate Email</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    disabled={true}
                    value={user.email}
                    id="user_email"
                    type="text"
                  />
                </div>
              </div>

              <div className="col l6 s12">
                <small className="right">candidate Phone</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                    id="your_phone"
                    type="text"
                  />
                  <label htmlFor="your_phone">Phone</label>
                </div>
              </div>
              <div className="col l12 s12">
                <small className="right">current Address</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    onChange={(e) => setAddress(e.target.value)}
                    id="your_address"
                    type="text"
                  />
                  <label htmlFor="your_address">Address</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col l6 s12">
                <select
                  defaultValue={"DEFAULT"}
                  onChange={(e) => setYearsOfExperience(e.target.value)}
                  className="browser-default  fabian_multiple_select"
                >
                  <option value="DEFAULT" disabled>
                    Years of Experience
                  </option>

                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>5+</option>
                </select>
              </div>
              <div className="col l6 s12">
                <select
                  defaultValue={"DEFAULT"}
                  onChange={(e) => setMonthsOfExperience(e.target.value)}
                  className="browser-default  fabian_multiple_select"
                >
                  <option value="DEFAULT" disabled>
                    Months of Experience
                  </option>

                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col l12 s12">
                <small className="right"> * atleast 5 characters minimum</small>
                <div className=" input-field input-outlined l6 ">
                  <textarea
                    onChange={(e) => setSkills(e.target.value)}
                    style={{
                      height: "80px",
                      background: "#fff",
                    }}
                    id="title"
                    type="text"
                    className="col  s12"
                  ></textarea>
                  <label htmlFor="title">Skills</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col l12">
                <p>
                  <label>
                    <input type="checkbox" />
                    <span className="check_span">
                      I agreed to the Terms and Conditions of ug365. I have
                      reviewed the default Mailer &amp; Communications settings.
                    </span>
                  </label>
                </p>
              </div>
            </div>

            <div className="center">
              <button
                style={{
                  marginBottom: "20px",
                }}
                disabled={invalid || submiting}
                onClick={candidateSubmitHandler}
                className="btn btn-large blue darken-2"
              >
                {submiting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default JobApplication;
