// eslint-disable-next-line
import React, { useState, useEffect, useMemo } from "react";

import { Link } from "react-router-dom";
import "./PostAd.css";
import { db, auth } from "../../lib/Firebase/firebase";
import { getDoc, doc } from "firebase/firestore";
import { Helmet } from "react-helmet-async";

import { Uganda } from "../../lib/Data/Locations/Locations";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { HeroCaroselsettings } from "../../lib/CaroselSetting/CaroseSetting";

import sublink from "../../lib/Data/Data";
import Loading from "../Loading/Loading";
import NavbarWrap from "../../components/Navbar/NavbarWrap";

function PostAd() {
  const [loading, setLoading] = useState(true);

  const { closeSubmenu } = useGlobalContext();

  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = getDoc(doc(db, "users", auth.currentUser.uid)).then(
      (docSnap) => {
        if (docSnap.exists) {
          setUser(docSnap.data());
          // console.log(docSnap.data());
          setLoading(false);
        }
      }
    );
    return () => unsubscribe;
  }, []);

  if (loading) {
    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>Start Selling Now Bantumart Uganda</title>

          <link rel="canonical" href="/post_ad" />
          <meta property="description" content="start selling Now" />
          <meta property="og:description" content="start selling Now" />
          <meta
            property="title"
            content="start selling NowA very important title"
          />
        </Helmet>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>start selling Now</title>

          <link rel="canonical" href="/post_ad" />
          <meta property="description" content="start selling Now" />
          <meta property="og:description" content="start selling Now" />
          <meta
            property="title"
            content="start selling NowA very important title"
          />
        </Helmet>

        <main>
          <NavbarWrap />
          <div
            style={{
              overflowX: "hidden !important",
              width: "100% !important",
            }}
            onMouseOver={closeSubmenu}
          >
            <div className="post_showcase_banner ">
              <div className="container">
                <h1 className="hide">post</h1>
                <h3 className="white-text">Post Ad</h3>
                <h4 className="white-text">Select Category First</h4>
              </div>
            </div>

            <div className="container">
              <div>
                <AdInfo user={user} />
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default PostAd;

const AdInfo = ({ user }) => {
  const [value, setValue] = useState(0);
  const { links } = sublink[value];
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [results, setResults] = useState([]);

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  //console.log(category, city, address);

  // console.log(Uganda);

  const invalid =
    category === "" ||
    subcategory === "" ||
    district === "" ||
    address === "" ||
    phoneNumber === "" ||
    phoneNumber.length !== 10 ||
    price === "" ||
    title === "" ||
    description === "";

  console.log(typeof phoneNumber);

  const data = [
    {
      category: category,
      subcategory: subcategory,
      district: district,
      division: division,
      address: address,
      phoneNumber: phoneNumber,
      price: price,
      email: user.email,
      userId: user.uid,
      userName: user.name,
      title: title,
      description: description,
    },
  ];

  useEffect(
    () => {
      if (category) {
        setResults([...results, data]);
        localStorage.setItem("data", JSON.stringify(data));
      }
    },
    // eslint-disable-next-line
    [
      category,
      subcategory,

      district,
      division,

      // data,
      results,
      address,
      phoneNumber,
      price,
      title,
      description,
    ]
  );

  return (
    <div>
      <div
        style={{
          padding: "0px 15px",
        }}
        className="container_levbitz"
      >
        <h6>Categories </h6>
        <p>Click on the arrows to find your desired category</p>
        <div className="row">
          <Slider {...HeroCaroselsettings}>
            {sublink.map((item, index) => {
              const { page, img, categoryUrl } = item;
              return (
                <div key={index}>
                  <div
                    style={{
                      background: value === index ? "red" : "#094c59",
                    }}
                    className="chip white-text"
                    onClick={() => {
                      setValue(index);
                      setIsSubmenuOpen(true);
                      setCategory(categoryUrl);
                    }}
                  >
                    {img ? (
                      <img src={img} alt="Contact Person" />
                    ) : (
                      <img
                        src="https://materializecss.com/images/yuna.jpg"
                        alt="Contact Person"
                      />
                    )}

                    {page}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="post_wrap">
        <div className="row">
          <div className="col l4 s12">
            <div
              style={{
                padding: "5px 15px",
                borderRadius: "10px",
                background: "#094c59",
              }}
              className="hide-on-med-and-down"
            >
              {/* <h2>{category}</h2>
            <h2>{subcategory}</h2>*/}
              {sublink.map((item, index) => {
                const { page, img, categoryUrl } = item;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // border: value === index ? "1px solid red" : "none",

                      padding: "0px 10px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setValue(index);
                      setIsSubmenuOpen(true);
                      setCategory(categoryUrl);
                    }}
                    key={index}
                  >
                    {img ? (
                      <img
                        style={{
                          objectFit: "contain",
                          marginRight: 4,
                        }}
                        width={25}
                        src={img}
                        alt=""
                      />
                    ) : null}
                    <h4
                      style={{
                        fontSize: value === index ? 15 : "1rem",
                        color: value === index ? "coral" : "white",
                        borderBotoom:
                          value === index
                            ? ".5px white solid"
                            : "2px red solid",
                      }}
                    >
                      {page}
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col l8 s12">
            <div className="row">
              <div className="col l12 s12">
                <select
                  defaultValue={"DEFAULT"}
                  onChange={(e) =>
                    //   console.log(
                    //     e.target.value.replace(/ /g, "_").toLowerCase(),
                    //     "e.target.value"
                    //   )
                    setSubcategory(
                      e.target.value.replace(/ /g, "_").toLowerCase()
                    )
                  }
                  disabled={isSubmenuOpen === false}
                  className="browser-default  fabian_multiple_select"
                >
                  <option value="DEFAULT" disabled>
                    Select Subcategory
                  </option>
                  {links.map((link, index) => {
                    const { icon, label } = link;
                    return (
                      <option key={index}>
                        {icon}
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div
                style={{
                  marginTop: "2rem",
                }}
                className="col l12 s12"
              >
                <select
                  onChange={(e) => setDistrict(e.target.value)}
                  disabled={isSubmenuOpen === false}
                  className="browser-default  fabian_multiple_select"
                >
                  <option value="" disabled selected>
                    Choose District
                  </option>
                  {Uganda.map((district) => {
                    return (
                      <option key={district.district} value={district.district}>
                        {district.district}
                      </option>
                    );
                  })}
                </select>
              </div>

              {district ? (
                <>
                  <div
                    style={{
                      marginTop: "2rem",
                    }}
                    className="col l12 s12"
                  >
                    <select
                      onChange={(e) => setDivision(e.target.value)}
                      className="browser-default  fabian_multiple_select"
                    >
                      <option disabled>Division</option>

                      {Uganda.filter((item) => {
                        if (item.district === district) {
                          return item;
                        } else {
                          return null;
                        }
                      }).map((item) => {
                        if (item.divisions.length > 0) {
                          return item.divisions.map((division, index) => {
                            return (
                              <option key={index} value={division}>
                                {division}
                              </option>
                            );
                          });
                        } else {
                          return null;
                        }
                      })}
                    </select>
                  </div>
                </>
              ) : null}

              <div className="col l12 s12">
                <small className="right">* required</small>
              </div>

              <div className="col l12 s12">
                <small className="right">current Address</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    onChange={(e) => setAddress(e.target.value)}
                    id="your_address"
                    type="text"
                  />
                  <label htmlFor="your_address">Address</label>
                </div>
              </div>
              <div className="col l12 s12">
                <div className="row">
                  <div className="col l6 s12">
                    <small
                      style={{
                        //if phorne is not available then display this message
                        color: phoneNumber.length !== 10 ? "red" : "green",
                      }}
                      className="right"
                    >
                      {" "}
                      *phone number
                    </small>
                    <div className=" input-field input-outlined l6 ">
                      <input
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        id="phone"
                        type="number"
                        required
                      />
                      <label htmlFor="phone">Phone Number</label>
                    </div>
                  </div>
                  <div className="col l6 s12">
                    <small className="right">*maximum price</small>
                    <div className=" input-field input-outlined l6 ">
                      <input
                        onChange={(e) => setPrice(e.target.value)}
                        id="price"
                        type="text"
                      />
                      <label htmlFor="price">Price</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col l12 s12">
                <div className="row">
                  <div className="col l6 s6">
                    <div className=" input-field input-outlined l6 ">
                      <input
                        disabled={true}
                        value={user.email}
                        id="your_address"
                        type="text"
                      />
                      {/*<label for="your_address">Address</label>*/}
                    </div>
                  </div>
                  <div className="col l6 s6">
                    <div className=" input-field input-outlined l6 ">
                      <input
                        disabled={true}
                        value={user.name}
                        id="your_address"
                        type="text"
                      />
                      {/*<label for="your_address">Address</label>*/}
                    </div>
                  </div>
                </div>
              </div>
              <h5>Product Information</h5>
              <p className="red-text">
                if you Add link(s) of any social media page or any webisite your
                ad will be delete and account will be banned
              </p>
              <div className="col l12 s12">
                <small className="right"> * atleast 5 characters minimum</small>
                <div className=" input-field input-outlined l6 ">
                  <textarea
                    onChange={(e) => setTitle(e.target.value)}
                    style={{
                      height: "80px",
                      background: "#fff",
                    }}
                    id="title"
                    type="text"
                    className="col  s12"
                  ></textarea>
                  <label htmlFor="title">Product Title</label>
                </div>
              </div>

              <div className="col l12 s12">
                <small className="right"> *25 characters minimum</small>
                <div className=" input-field input-outlined l6 ">
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      height: "200px",
                      background: "#fff",
                      padding: "10px 10px",
                      resize: "vertical",
                    }}
                    // minLength
                    id="description"
                    type="text"
                  ></textarea>
                  <label htmlFor="description">Product Description</label>
                </div>
              </div>

              <div className="col l12 s12">
                <div className="row">
                  <div className="col l12">
                    <Link to="/photo_upload" disabled={invalid} className="btn">
                      Procced
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
