import React, { useState } from "react";
import NavBarTwo from "../../BusinessComponents/NavBarTwo/NavBarTwo";
import "./Career.css";
import { Link } from "react-router-dom";
import { JobList } from "../../../lib/JobList/JobList";
import { Helmet } from "react-helmet-async";

//console.log(JobList);

function Careers() {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title> bantumart careers</title>

        <link rel="canonical" href="/jobs" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        <meta
          name="description"
          content="Join our team get updates with bantumart careers"
        />
        <meta
          property="og:description"
          content="Join our team get updates with bantumart careers"
        />
        <meta property="title" content="career" />

        <meta
          name="keywords"
          content="jobs , software tester  , social media manager
    "
        />
      </Helmet>
      <NavBarTwo />

      <div className="career_wrap">
        <div className="container">
          <div className="row">
            <div className="col l6 ">
              <div>
                <h1 className="hide">
                  bantumart Jobs Get started with bantumart jobs
                </h1>
                <h2 className="white-text">Get updates on our job listing</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row ">
          <div className="col l12 s12">
            <div
              style={{
                marginTop: "20px",
                marginBottom: "0px !important",
              }}
              className="row "
            >
              <div className="col l6 s12"></div>
              <div className="col l6 s12">
                <h6>Find Job</h6>

                <div className=" input-field input-outlined l6 ">
                  <input
                    style={{
                      marginTop: "20px",
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    // onChange={(e) => setPrice(e.target.value)}
                    id="price"
                    type="text"
                  />
                  <label htmlFor="price">Search Job</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div
            style={{
              marginTop: "-45px",
            }}
            className="col l8 s12"
          >
            <h6
              style={{
                marginTop: "0px",
              }}
            >
              Fetured Jobs
            </h6>

            <div>
              {JobList.filter((value) => {
                if (searchTerm === "") {
                  return value;
                } else {
                  return value.title
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                }
              })
                .slice(0, 10)

                .map((job) => {
                  return (
                    <JobTampe id={job.id} key={job.id} title={job.title} />
                  );
                })}
            </div>
          </div>

          <div className="col l6 s12"></div>
        </div>
      </div>
    </div>
  );
}

export default Careers;

const JobTampe = ({ title, id }) => {
  return (
    <>
      <Link to={`/single_job/${id}`}>
        <div
          style={{
            borderLeft: "2px solid #ff4848",
            borderRadius: "5px",
            padding: "10px",
          }}
          className="row white"
        >
          <h6>{title}</h6>
          <div className="col l6 s12">
            <div>
              <div className="row">
                <div className="col l6 s6">
                  <small>Location</small>
                  <p>Remote</p>
                </div>
                <div className="col l6 s6">
                  <small> Category</small>
                  <p>Functional</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col l3 s8">
            <div className="third_column">
              <h6>
                <small>Type </small> :full-time
              </h6>
              <h6>
                <small>Posted: </small> 1 day ago
              </h6>
            </div>
          </div>
          <div className="col l2 s4">
            <div className="right fouth_column">
              <Link
                style={{
                  border: "2px solid #ff4848",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                className="red-text"
                to={`/single_job/${id}`}
              >
                Details
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
