import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDQUPQQmWoUywOQV1mmcfqz4pXZ3L2D8m4",
  authDomain: "bantumart-kenya.firebaseapp.com",
  projectId: "bantumart-kenya",
  storageBucket: "bantumart-kenya.appspot.com",
  messagingSenderId: "987255618842",
  appId: "1:987255618842:web:163adcf0f580698864c0a3"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  db,
  storage,
  //GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
};
