import React from "react";

import "./Pricing.css";
import { FaFire } from "react-icons/fa";
import { BsXDiamondFill } from "react-icons/bs";
import { GiCrystalize } from "react-icons/gi";
// import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

function PricingPage() {
  return (
    <>
      <div class="navbar-fixed">
        <nav
          style={{
            background: "#094c59",
          }}
          className="z-depth-0"
        >
          <div className="container">
            <div class="nav-wrapper">
              <Link to="/" class="brand-logo left">
                Ug365
              </Link>
              <ul id="nav-mobile" class="right ">
                <li className="hide-on-med-and-down">
                  <a href="sass.html">Sass</a>
                </li>
                <li className="hide-on-med-and-down">
                  <a href="badges.html">Components</a>
                </li>
                <li>
                  <Link to="/post_add">Post Ad</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className="pricing__section">
        <div className="pricing__wrapper">
          <h1 className="pricing__heading">Pricing</h1>
          <div className="pricing__container">
            <Link to="/sign-up" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <FaFire />
                </div>
                <h3>Free Ad</h3>
                <h4>$8.99</h4>
                <p>per month</p>
                <ul className="pricing__container-features">
                  <li>100 Transactions</li>
                  <li>2% Cash Back</li>
                  <li>$10,000 Limit</li>
                </ul>
                <button buttonSize="btn--wide" buttonColor="primary">
                  Choose Plan
                </button>
              </div>
            </Link>
            <Link to="/sign-up" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <FaFire />
                </div>
                <h3>Basic</h3>
                <h4>$8.99</h4>
                <p>per month</p>
                <ul className="pricing__container-features">
                  <li>100 Transactions</li>
                  <li>2% Cash Back</li>
                  <li>$10,000 Limit</li>
                </ul>
                <button buttonSize="btn--wide" buttonColor="primary">
                  Choose Plan
                </button>
              </div>
            </Link>
            <Link to="/sign-up" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <BsXDiamondFill />
                </div>
                <h3>Futured Ad</h3>
                <h4>$29.99</h4>
                <p>per month</p>
                <ul className="pricing__container-features">
                  <li>1000 Transactions</li>
                  <li>3.5% Cash Back</li>
                  <li>$100,000 Limit</li>
                </ul>
                <button buttonSize="btn--wide" buttonColor="blue">
                  Choose Plan
                </button>
              </div>
            </Link>
            <Link to="/sign-up" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <GiCrystalize />
                </div>
                <h3>Vip Ad</h3>
                <h4>$99.99</h4>
                <p>per month</p>
                <ul className="pricing__container-features">
                  <li>Unlimited Transactions</li>
                  <li>5% Cash Back</li>
                  <li>Unlimited Spending</li>
                </ul>
                <button buttonSize="btn--wide" buttonColor="primary">
                  Choose Plan
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default PricingPage;
