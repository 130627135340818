import React from "react";
import NavBarTwo from "../../Business/BusinessComponents/NavBarTwo/NavBarTwo";
import AbtImg from "../../lib/images/abt.png";
import SellImg from "../../lib/images/seller.png";
import BuyImg from "../../lib/images/buyer.png";
import { Helmet } from "react-helmet-async";

function AboutPage() {
  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>About banutmart</title>

        <link rel="canonical" href="/about" />
        <meta
          property="description"
          content="uganda's largest b2b and c2c online platform"
        />
        <meta
          property="og:description"
          content="uganda's largest b2b and c2c online platform"
        />
      </Helmet>
      <NavBarTwo />
      <div
        className="white"
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          marginBottom: 300,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col l6">
              <div>
                <h5>About Us</h5>
                <p>
                  <a
                    href="http://bantumart.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bantumart
                  </a>{" "}
                  is Uganda’s largest online B2B marketplace, connecting buyers
                  with suppliers. With 60% market share of the online B2B
                  Classified space in Uganda, the channel focuses on providing a
                  platform to Small & Medium Enterprises (SMEs), Large
                  Enterprises as well as individuals. Founded in 2022, the
                  company’s mission is ‘to make doing business easy’.
                </p>
              </div>
            </div>
            <div className="col l6">
              <img className="responsive-img" src={AbtImg} alt="" />
            </div>
          </div>
        </div>

        <div
          style={{
            padding: 50,
          }}
        >
          <h2 className="red-text center">
            6.8 Million Buyers | 1.2 Million Suppliers | 86 Million Products &
            Services
          </h2>
        </div>

        <div className="container">
          <div className="row">
            <div className="col l6">
              <div>
                <h5>bantuMART for Buyers</h5>
                <div>
                  <p>
                    – Convenience of connecting with sellers anytime, anywhere
                  </p>
                  <p>
                    – Wider marketplace with a range of products and suppliers
                  </p>
                  <p> – Payment Protection Program</p>
                </div>
              </div>
            </div>

            <div className="col l6">
              <div>
                <img className="responsive-img" src={BuyImg} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col l6">
              <div>
                <img className="responsive-img" src={SellImg} alt="" />
              </div>
            </div>
            <div className="col l6">
              <div>
                <h5>bantuMART for Suppliers / Sellers /Service Providers</h5>
                <div>
                  <p>– Enhanced business visibility</p>
                  <p>– Increased credibility for your brand</p>
                  <p> – Lead Management System</p>
                  <p> – Instant Payment Solution: Pay with bantuMart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
