import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import Navbar from "../../components/Navbar/Navbar";
import { BsWhatsapp, BsFillTelephoneFill } from "react-icons/bs";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
//import "./Promote.css";
import { Link } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import Apeiroz from "../../lib/images/ads/apeiroz.gif";
//import DefaultImg from "../../lib/images/d4.gif";
import { Helmet } from "react-helmet-async";

import "./PostedAddDetails.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import ExpiredAd from "../../components/ExpiredAd/ExpiredAd";

function PostedAddDetails() {
  const { closeSubmenu } = useGlobalContext();
  const { id } = useParams();

  //console.log(useParams());
  const [laoding, setLoading] = useState(true);

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);

  const [email, setEmail] = useState("");

  useEffect(() => {
    const docRef = doc(db, "ads", id);
    getDoc(docRef).then((doc) => {
      setCategory(doc.data().category);
      setSubcategory(doc.data().subcategory);
      setDistrict(doc.data().district);
      setDivision(doc.data().division);
      setAddress(doc.data().address);
      setPhoneNumber(doc.data().phoneNumber);
      setPrice(doc.data().price);
      setTitle(doc.data().title);
      setDescription(doc.data().description);
      setEmail(doc.data().email);
      setImages(doc.data().images);
    });
    setLoading(false);
  }, [id]);

  const [showNum, setShowNum] = useState(false);
  const [showAdd, setShowAdd] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  //const num = "+256709874279";

  return (
    <div>
      {laoding ? (
        <div>Loading...</div>
      ) : (
        <>
          <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta data-rh="true" charset="utf-8" />
            <link rel="icon" href={`${images[0]}`} />

            <link rel="canonical" href={`/item/${id}`} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            ></meta>

            <meta
              name="description"
              content={`${description}  ${district} ${division}`}
            />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={images[0]} />
          </Helmet>
          <NavbarWrap />

          <div onMouseOver={closeSubmenu}>
            {showPopup ? (
              <ImagePoping setShowPopup={setShowPopup} images={images} />
            ) : null}
            <div className="levbitz_container details_showcase_wrap">
              <div className="container">
                <div className="row">
                  <div className="col l10 s12">
                    <div
                      style={{
                        background: "#094c59",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <h6>Add Details</h6>

                      <nav className="transparent z-depth-0">
                        <div className="nav-wrapper">
                          <div className="col s12">
                            <Link to="/" className="breadcrumb detail_bread">
                              Home
                            </Link>
                            <a href="#!" className="breadcrumb detail_bread">
                              {category}
                            </a>
                            <a href="#!" className="breadcrumb detail_bread">
                              {subcategory.replace(/_/g, " ")}
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "none",
                      }}
                    >
                      {email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container white detail_wrap ">
              <div
                style={{
                  marginBottom: "50px",
                  padding: "10px 20px",
                  borderRadius: "10px !important",
                }}
              >
                <div className="row">
                  <div className="col l7 s12 ">
                    <div>
                      <small
                        style={{
                          fontSize: 20,
                          textTransform: "capitalize",
                        }}
                      >
                        title
                      </small>
                      <p>{title}</p>

                      <div>
                        <small>description</small>

                        {!description
                          ? "details will be available soon"
                          : description
                              .toString()
                              .split(".")
                              .map((item, index) => {
                                return (
                                  <p key={index} className="summaryTwo">
                                    {item}
                                  </p>
                                );
                              })}
                      </div>

                      <p>{district} District</p>

                      <small>address</small>
                      <p>{address}</p>

                      <div className="row">
                        <div className="col l6">
                          <div>
                            <small>phone number</small>
                            <p>{phoneNumber}</p>
                          </div>
                        </div>
                        <div className="col l6">
                          <div>
                            <small>price</small>

                            <p>KSh: {price}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col l4 offset-l1 s12">
                    <div className="hide-on-med-and-down">
                      {showAdd ? (
                        <div
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #ddd",
                            padding: "10px",
                            background: "#000",
                          }}
                        >
                          <small
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowAdd(false)}
                          >
                            close Ad
                          </small>
                          <a href="https://play.google.com/store/apps/details?id=com.levson.apeiroz">
                            <img
                              style={{
                                borderRaduis: "1000px !important",
                              }}
                              className="responsive-img"
                              src={Apeiroz}
                              alt=""
                            />
                          </a>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <a
                        onClick={() => {
                          setShowNum(true);
                        }}
                        href={`tel:${phoneNumber}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "10px 0",
                            borderRadius: "5px",
                            padding: "10px 0px",
                            background: "#094c59",
                          }}
                          className="   center"
                        >
                          <span
                            style={{
                              marginRight: "15px",
                            }}
                          >
                            <BsFillTelephoneFill size={18} color="#fff" />
                          </span>
                          <span
                            style={{
                              fontSize: "22px",
                              textTransform: "capitalize",
                            }}
                            className="white-text"
                          >
                            {showNum ? phoneNumber : "telephone"}
                          </span>
                        </div>
                      </a>
                    </div>

                    {/*   <p>{num}</p>
                    <p>{num.length}</p>
                    <p>{phoneNumber.substring(4)}</p>
                    <p>{phoneNumber.length}</p>

                    <h3>
                      {phoneNumber.length > 10
                        ? phoneNumber.substring(4)
                        : phoneNumber}
                    </h3>
                      */}
                    <div className="row">
                      <a
                        href={`https://wa.me/256${parseInt(
                          phoneNumber.length > 10
                            ? phoneNumber.substring(4)
                            : phoneNumber
                        )}?text=Hi,I saw your Ad on bantuMart (bantumart.com): %0a*${title}* %0a I would like to know if the service or product is available.%0a*Ad Address*%0ahttps://bantumart.com/pad/${id}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "10px 0",
                            borderRadius: "5px",
                            padding: "10px 0px",
                            background: "#25D366",
                          }}
                          className="   center"
                        >
                          <span
                            style={{
                              marginRight: "15px",
                            }}
                          >
                            <BsWhatsapp size={26} color="#fff" />
                          </span>
                          <span
                            style={{
                              fontSize: "22px",
                              textTransform: "capitalize",
                            }}
                            className="white-text"
                          >
                            whatsapp
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <hr />
                <small className="right">click any image to zoom out</small>
                <h3>Images</h3>

                <div className="row">
                  {images ? (
                    <>
                      {images.slice(0, 2).map((imgUrl) => {
                        return (
                          <div key={imgUrl} className="col l4 s12 ">
                            <img
                              onClick={() => setShowPopup(true)}
                              src={imgUrl}
                              alt="img"
                              className="responsive-img"
                              style={{
                                borderRadius: 10,
                              }}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : null}

                  {images ? (
                    <>
                      {images.slice(2, 3).map((imgUrl) => {
                        return (
                          <div key={imgUrl} className="col l4 s12 ">
                            <img
                              onClick={() => setShowPopup(true)}
                              src={imgUrl}
                              alt="img"
                              className="responsive-img"
                              style={{
                                borderRadius: 10,
                                opacity: 0.2,
                              }}
                            />

                            <div
                              style={{
                                marginTop: -150,
                                marginLeft: "30%",
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: "2.5rem",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                {images.length - 2}+
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>

                <div className="row  report_wrap">
                  <div className="col l6 s12">
                    <Sharing
                      id={id}
                      title={title}
                      images={images[0]}
                      description={description}
                    />
                  </div>
                  <div className="col l6 s12">
                    <div className="right">
                      <Link
                        to={`/report/${id}/${phoneNumber}/${email}/${title}`}
                        className="btn red z-depth-0"
                      >
                        Report this Ad
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PostedAddDetails;

const Sharing = ({ id, title, images, description }) => {
  const shareUrl = `https://bantumart.com/item/${id}`;

  return (
    <div
      style={{
        background: "#0000",

        width: "100%",
      }}
    >
      <h5>Share with to friends</h5>
      <FacebookShareButton
        url={shareUrl}
        quote={title}
        hashtag={"bantumart"}
        windowHeight={600}
        windowWidth={600}
        title="jkfjfjkdfjkfdjkfdkj"
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <WhatsappShareButton url={shareUrl} quote={title} hashtag={"ug365"}>
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>
      <InstapaperShareButton url={shareUrl} title={title}>
        <InstapaperIcon size={40} round={true} />
      </InstapaperShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
      <EmailShareButton url={shareUrl} subject={title} body={description}>
        <EmailIcon size={40} round={true} />
      </EmailShareButton>
      <FacebookMessengerShareButton url={shareUrl} title={title} appId="1234">
        <FacebookMessengerIcon size={40} round={true} />
      </FacebookMessengerShareButton>
    </div>
  );
};

const ImagePoping = ({ images, setShowPopup }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,

    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>;
    },
  };
  return (
    <>
      <div className="img_popping_wrap">
        <div className="container">
          <div>
            <div className="col l12">
              <p
                onClick={() => setShowPopup(false)}
                style={{
                  margin: "0px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                className="white-text right"
              >
                close
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              margin: "0 auto !important",
              padding: "0px !important",
            }}
          >
            <div className="col l12 s12">
              {images ? (
                <Slider {...settings}>
                  {images.map((imgUrl, index) => {
                    return (
                      <div key={index}>
                        <img
                          alt={imgUrl}
                          src={imgUrl}
                          className="responsive-img"
                          style={{
                            borderRadius: 10,
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    );
                  })}
                </Slider>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
