import React from "react";
import { Link } from "react-router-dom";
import NavBarTwo from "../../BusinessComponents/NavBarTwo/NavBarTwo";
import "./WalkThrough.css";
import { Helmet } from "react-helmet-async";

function WalkThrough() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>how to start selling on bantumart</title>

        <link rel="canonical" href="/walk-through" />
        <meta property="description" content="start selling Now" />
        <meta property="og:description" content="start selling Now" />
        <meta property="title" content="how to selle on bantumart" />
      </Helmet>
      <main>
        <div className="white">
          <NavBarTwo />
          <div className="container  walk_through_wrap ">
            <div
              style={{
                marginBottom: "0px",
              }}
              className="row "
            >
              <div className="col l2  s12 "></div>
              <div className="col l8  s12 ">
                <div
                  style={{
                    marginTop: "120px",
                  }}
                >
                  <h4>
                    Bantumart is the best place to sell anything to real people.
                  </h4>
                  <div className="lower_design"></div>
                  <p>
                    It is the biggest free online classified with an advanced
                    security system. We provide a simple hassle-free solution to
                    sell and buy almost anything.
                  </p>
                </div>
              </div>
              <div className="col l2  s12 "></div>
              {/*<div className="col l6 s12">
            <div
              style={{
                marginTop: "80px",
              }}
            >
              <SideImg
                src={
                  "https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Bantumart%2F1.png?alt=media&token=6a1ac162-c24c-450f-a886-68bf4fd71088"
                }
              />
            </div>
              </div>*/}
            </div>

            <div className="row ">
              <div className="col l5">
                <div
                  style={{
                    marginTop: "30%",
                  }}
                >
                  <SideImg
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Ug365%2F2.png?alt=media&token=b73e1079-5f7b-4443-aab8-0090c3323eca"
                    }
                  />
                </div>
              </div>

              <div className="col l7">
                <div>
                  <h4>How to sell on Bantumart?</h4>
                  <div className="lower_design"></div>

                  <h6>
                    1. <Link to="/sign_up">Register</Link>
                  </h6>

                  <p>
                    Register using your e-mail and phone number (or do it via
                    Facebook or Google). Make sure you’re entering a correct
                    phone number, so your clients could reach you!
                  </p>
                  <h6>2. Make photos of your item.</h6>
                  <p>
                    Feel free to make a lot of photos using your smartphone.
                    Make sure they show your item in the best light
                  </p>
                  <h5>3. Press SELL .</h5>
                  <p>
                    Choose a proper category, upload your photos and write a
                    clear title and full description of your item. Enter a fair
                    price, select attributes and send your advert to review!
                  </p>

                  <h6>4. Answer the messages and calls from your clients</h6>
                  <p>
                    If everything is ok with your advert, it’ll be on Bantumart
                    in a couple of hours after sending to moderation. We’ll send
                    you a letter and notification when your advert goes live.
                    Check your messages and be ready to earn money! Do you want
                    to sell like a pro? Check out our Premium Services .
                  </p>
                  <p>
                    It is the biggest free online classified with an advanced
                    security system. We provide a simple hassle-free solution to
                    sell and buy almost anything.
                  </p>

                  <span
                    className="center"
                    style={{
                      background: "#094c59",
                      padding: " 2px 10px",
                      borderRadius: "5px",
                      margin: "auto",
                    }}
                  >
                    <Link to="/" className="white-text " style={{}}>
                      Start Selling
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col l6">
                <div>
                  <h4>How to buy on Bantumart?</h4>
                  <div className="lower_design"></div>

                  <h6>1. Search for the item.</h6>
                  <p>
                    Find what you need using search panel and filters. We have
                    over a million adverts, choose exactly what you are looking
                    for.
                  </p>

                  <h6>2. Contact a seller.</h6>
                  <p>
                    You may use chat on Bantumart or call them via phone.
                    Discuss all the details, negotiate about the price.
                  </p>
                  <h6>3. Take your item or order a delivery.</h6>
                  <p>
                    We check our sellers carefully, but it’s always better to
                    check twice, right? Meet a seller in public place and be
                    sure to pay only after collecting your item.
                  </p>

                  <h6>4. Leave your feedback about the seller.</h6>
                  <p>
                    Feel free to tell us about your purchase. Your feedback will
                    be published online on the seller’s page and will be very
                    helpful for other buyers. Let’s build a safe and
                    professional business community together!
                  </p>
                  <button>Start Sourcing</button>
                </div>
              </div>

              <div className="col l6">
                <SideImg
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Ug365%2F3.png?alt=media&token=8497911e-c3aa-4909-93b0-dc85b14cc666"
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col l5">
                <SideImg
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Ug365%2F4.png?alt=media&token=2de2de25-61d6-484d-9e2f-622ee6dea5b1"
                  }
                />
              </div>
              <div className="col l7">
                <div>
                  <h4>Safety</h4>
                  <div className="lower_design"></div>

                  <h6>1. General</h6>
                  <p>
                    We are highly focused on the security and can solve any
                    issues in short-terms. That’s why we ask you, kindly, to
                    leave a review after purchasing. If you run into any
                    problems with a seller, you can report us and Bantumart Team
                    will check this seller as soon as possible.
                  </p>

                  <h6>2. Personal safety tips.</h6>

                  <ul>
                    <li>- Do not pay in advance, even for the delivery</li>
                    <li>- Try to meet at a safe, public location</li>
                    <li>
                      - Check the item BEFORE you buy it - Pay only after
                      collecting the item
                    </li>
                  </ul>
                  <h6>3. Secure payments.</h6>

                  <p>
                    Jiji provides Premium Services for those who want to sell
                    and earn more. We accept both online and offline payments
                    for these services. We guarantee secure and reliable
                    payments on Jiji.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col l7">
                <div>
                  <h4>Sell like a pro!</h4>
                  <div className="lower_design"></div>

                  <h6>Pay attention to the details.</h6>
                  <p>
                    Make good photos of your goods, write clear and detailed
                    description.
                  </p>

                  <h6>2. Answer quickly.</h6>
                  <p>
                    Don’t make your buyer wait for your message for days. Be
                    online or get SMS notifications on your messages.
                  </p>

                  <h6>3. Use Premium Services to get 15x more customers!</h6>

                  <p>
                    Your adverts will appear at the top of the page and you will
                    sell faster! How does it work?
                  </p>
                </div>
              </div>

              <div className="col l4 offset-l1">
                <SideImg
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Ug365%2F5.png?alt=media&token=7cc65a16-92df-47f0-871f-a4d5c1b06438"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default WalkThrough;

const SideImg = ({ src }) => {
  return (
    <div>
      <img className="responsive-img" src={src} alt="" />
    </div>
  );
};

//264
