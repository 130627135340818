import React, { useState, useEffect } from "react";
import { db, storage } from "../../lib/Firebase/firebase";
import { Link, useNavigate, Navigate } from "react-router-dom";

import {
  addDoc,
  arrayUnion,
  collection,
  serverTimestamp,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import NavbarWrap from "../../components/Navbar/NavbarWrap";

function PhotoUpload() {
  const { closeSubmenu } = useGlobalContext();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [result, setResult] = useState([]);
  const [myPreview, setMyPreview] = useState([]);
  //const [identifier, setIdentifier] = useState([]);
  const [activation, setActivation] = useState(false);

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");

  const onChangeHandler = async (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const newImage = event.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }

    const filesArray = Array.from(event.target.files).map((file, index) =>
      URL.createObjectURL(file, index)
    );

    setMyPreview((prevImages) => prevImages.concat(filesArray));
    Array.from(event.target.files).map(
      (file, index) => URL.revokeObjectURL(file, index) // avoid memory leak
    );
  };

  //console.log(images.length);
  //console.log(myPreview);

  const handleUpload = async () => {
    setUploading(true);
    const docRef = await addDoc(collection(db, "ads"), {
      createdAt: serverTimestamp(),
      active: false,
      banned: false,
      promotionType: "normal",
      platform: "web",
      category: category,
      subcategory: subcategory,
      district: district,
      division: division,
      address: address,
      phoneNumber: phoneNumber,
      price: price,
      title: title.toLowerCase(),
      description: description.toLowerCase(),
      email: email,
      userId: userId,
      userName: userName,
      views: 1,
    });
    await Promise.all(
      images.map(async (file) => {
        const fileRef = ref(storage, `photos/${docRef.id}/${file.name}`);
        const uploadTask = uploadBytes(fileRef, file, "data_url").then(
          async () => {
            const downloadURL = await getDownloadURL(fileRef);

            await updateDoc(doc(db, "ads", docRef.id), {
              images: arrayUnion(downloadURL),
            });
            // await setIdentifier(docRef.id);
            await setActivation(true);
            await setUploading(false);
            await localStorage.removeItem("data");
            await navigate(`/promote/${docRef.id}`);
          }
        );
      })
    );
  };

  const renderPhotos = (source) => {
    if (source != null) {
      return source.map((photo, index) => {
        // console.log(index);
        return (
          <div className="col l2 s6" key={index}>
            <p>{photo.id}</p>

            <img
              width={"100%"}
              style={{
                objectFit: "contain",
              }}
              className="responsive-image"
              src={photo}
              alt=""
            />

            {/*<button
              onClick={() => {
                //delete and update fileList
                setImages((prevState) =>
                  prevState.filter((item) => item.id !== photo.id)
                );
                setMyPreview((prevState) =>
                  prevState.filter((item) => item !== photo)
                );
              }}
            >
              delete
            </button>*/}
          </div>
        );
      });
    } else {
      return null;
    }
  };

  const navigation = useNavigate();
  useEffect(() => {
    //getData();
    const data = JSON.parse(localStorage.getItem("data"));

    if (data) {
      data.map((item) => {
        setResult([...result, item]);

        setCategory(item.category);
        setSubcategory(item.subcategory);
        setDistrict(item.district);
        setDivision(item.division);
        setAddress(item.address);
        setPhoneNumber(item.phoneNumber);
        setPrice(item.price);
        setTitle(item.title);
        setDescription(item.description);
        setEmail(item.email);
        setUserName(item.userName);
        setUserId(item.userId);
      });
    } else {
      setResult([]);
      navigation("/post_add");
      // alert("no resati");
    }
    //setResult(data);
  }, []);

  useEffect(() => {
    setImages(images);
    setMyPreview(myPreview);
  }, [images, myPreview]);
  return (
    <>
      <NavbarWrap />
      <div onMouseOver={closeSubmenu}>
        <div className="container">
          <div
            style={{
              marginTop: "50px",
            }}
            className="row"
          >
            <div className="col l4 s12">
              <div>
                <label className="btn" htmlFor="upload">
                  Click Upload Photos
                </label>
                <input
                  className="btn"
                  type="file"
                  name="upload"
                  id="upload"
                  style={{ display: "none" }}
                  placeholder="hffhfhhf"
                  multiple
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="col l8 s12">
              <div>
                <small className="right red-text">
                  {" "}
                  *minimum should be 3 images and maximum should be 5 images
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              style={{
                padding: "10px",
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
                width: "100%",
                height: "300px",
                marginTop: "10px",
              }}
            >
              <div className="result">{renderPhotos(myPreview)}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col l4">
                {images.length > 2 ? (
                  activation ? null : (
                    <div onClick={handleUpload}>
                      {uploading ? (
                        <>
                          <button
                            className="btn waves-effect waves-light"
                            type="submit"
                            name="action"
                          >
                            uploading...
                            <i className="material-icons right">
                              <div className="preloader-wrapper small active">
                                <div className="spinner-layer spinner-red-only">
                                  <div className="circle-clipper left">
                                    <div className="circle"></div>
                                  </div>
                                  <div className="gap-patch">
                                    <div className="circle"></div>
                                  </div>
                                  <div className="circle-clipper right">
                                    <div className="circle"></div>
                                  </div>
                                </div>
                              </div>
                            </i>
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn">continue</button>
                        </>
                      )}
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>

          <div className="container">
            {result ? (
              <div
                style={{
                  marginBottom: "50px",
                }}
              >
                <h6>Add Details</h6>
                <div className="row">
                  <div className="col l2 s3">
                    <div
                      style={{
                        border: "1px solid indigo",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        style={{
                          color: "indigo",
                          fontSize: "15px",
                          margin: "0",
                          padding: "0",
                        }}
                        className="center"
                      >
                        {category}
                      </p>
                    </div>
                  </div>
                  <div className="col l2 s6">
                    <div
                      style={{
                        border: "1px solid indigo",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        style={{
                          color: "indigo",
                          fontSize: "15px",
                          margin: "0",
                          padding: "0",
                        }}
                        className="center"
                      >
                        {subcategory}
                      </p>
                    </div>
                  </div>
                  <div className="col l2 s3">
                    <div
                      style={{
                        border: "1px solid indigo",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        style={{
                          color: "indigo",
                          fontSize: "15px",
                          margin: "0",
                          padding: "0",
                        }}
                        className="center"
                      >
                        {district}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col l12">
                    <small>Town</small>
                    <p>{division}</p>
                    <small>address</small>
                    <p>{address}</p>
                    <small>phone number</small>
                    <p>{phoneNumber}</p>
                    <small>price</small>

                    <p>{price}</p>
                    <small>title</small>
                    <p>{title}</p>
                    <small>description</small>
                    <p>{description}</p>
                    <small>email</small>
                    <p>{email}</p>
                    <small>user</small>
                    <p>{userName}</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoUpload;
