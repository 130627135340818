import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";
import ThumbNailSilders from "../../components/ThumbNailSiders/ThumbNailSilders";

import { Uganda } from "../../lib/Data/Locations/Locations";
import { Helmet } from "react-helmet-async";
import { AdAge } from "../../lib/Data/Data";
import { Link } from "react-router-dom";

import "./MegaSearch.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import LaodingImg from "../../lib/images/loading.png";
import NoMoreData from "../../components/NoMoreData/NoMoreData";

function MegaSearch() {
  const { page, categoryUrl, label, subCategoryUrl } = useParams();
  const { closeSubmenu } = useGlobalContext();

  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");

  const [result, setResult] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);
  //filter ro
  const [orderFormat, setOrderFormat] = useState("desc");
  const [noDataFound, setNoDataFound] = useState(false);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      where("subcategory", "==", subCategoryUrl),
      // where("district", "==", district),
      orderBy("createdAt", orderFormat),
      limit(12)
    );

    onSnapshot(q, (snapshoot) => {
      if (snapshoot.size === 0) {
        // alert("collection is empty");
        setNoDataFound(true);
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        // console.log(snapshoot.docs);
        setNoDataFound(false);
        setLoading(true);
        // setTimeout(() => {

        const term = snapshoot.docs.map((doc) =>
          // console.log(doc.data())
          ({
            id: doc.id,
            data: doc.data(),
          })
        );

        setResult(term);
        setLoading(false);
        //get the last doc
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
      }
    });
    // eslint-disable-next-line
  }, [orderFormat, page, categoryUrl, subCategoryUrl]);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    setLoading(true);

    const q = query(
      NewData,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      where("subcategory", "==", subCategoryUrl),
      orderBy("createdAt", orderFormat),
      startAfter(lastDoc),

      limit(12)
    );

    getDocs(q).then((snapshoot) => {
      // setLoading(true);
      // setTimeout(() => {
      if (snapshoot.size === 0) {
        //  alert("No more data");
        setLoading(false);
        setCollectionIsEmpty(true);
        //setResults("no more data");
      } else {
        const term = snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setResult([...result, ...term]);
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{subCategoryUrl} on bantumart</title>

        <link
          rel="canonical"
          href={`/mega/${page}/${categoryUrl}/${label}/${subCategoryUrl}`}
        />
        <meta
          property="description"
          content={` buy and sell ${subCategoryUrl} .  Are your looking for ${page} , ${categoryUrl} or ${subCategoryUrl} `}
        />
        <meta property="og:description" content="start selling Now" />
        <meta
          property="title"
          content="start selling NowA very important title"
        />
      </Helmet>
      <main>
        <NavbarWrap />

        <div onMouseOver={closeSubmenu}>
          <div className="levbitz_container_two ">
            <div className="mega_showcase_banner">
              <div>
                {
                  <h5 className="white-text">
                    <span>
                      <Link className="white-text" to={`/${categoryUrl}`}>
                        {categoryUrl.replace(/_/g, " ")}
                      </Link>
                    </span>
                    <span></span>
                    <span>{subCategoryUrl.replace(/_/g, " ")}</span>
                  </h5>
                }

                <div className="">
                  <div className="row">
                    <div className="col l4 s12">
                      <div
                        style={{
                          width: "300px",
                        }}
                      >
                        <select
                          style={{
                            height: "30px",
                            width: "200px",
                            textTransform: "lowercase",
                          }}
                          onChange={(e) => setDistrict(e.target.value)}
                          className="browser-default  fabian_multiple_select text-white btn btn-small"
                        >
                          <option value="" disabled selected>
                            Select District
                          </option>
                          {Uganda.map((district) => {
                            return (
                              <option
                                className="text-white"
                                key={district.district}
                                value={district.district}
                              >
                                {district.district}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    {district ? (
                      <div className="col l4 s12">
                        <select
                          style={{
                            marginTop: "1rem",

                            border: "transparent",
                            background: "transparent",
                          }}
                          onChange={(e) => setDivision(e.target.value)}
                          className="browser-default  fabian_multiple_select"
                        >
                          <option disabled selected>
                            Division
                          </option>

                          {Uganda.filter((item) => {
                            if (item.district === district) {
                              return item;
                            } else {
                              return null;
                            }
                          }).map((item) => {
                            if (item.divisions.length > 0) {
                              return item.divisions.map((division, index) => {
                                return (
                                  <option key={index} value={division}>
                                    {division}
                                  </option>
                                );
                              });
                            } else {
                              return null;
                            }
                          })}
                        </select>
                      </div>
                    ) : null}

                    <div className="col l4">
                      <div
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        <button
                          onClick={() => {
                            setDistrict("");
                            setDivision("");
                          }}
                          className="btn"
                        >
                          clear filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="levbitz_container_two">
            <div className="row">
              <div className="col l3">
                <div
                  style={{
                    padding: "10px 10px",
                    borderRadius: "10px",
                  }}
                  className="white"
                >
                  <p>Filters</p>

                  <div
                    style={{
                      width: "300px",
                    }}
                  >
                    <select
                      style={{
                        height: "30px",
                        width: "200px",
                        textTransform: "lowercase",
                      }}
                      onChange={(e) => setDistrict(e.target.value)}
                      className="browser-default  fabian_multiple_select text-white btn btn-small"
                    >
                      <option value="" disabled selected>
                        Select District
                      </option>
                      {Uganda.map((district) => {
                        return (
                          <option
                            className="text-white"
                            key={district.district}
                            value={district.district}
                          >
                            {district.district}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div>
                    {district ? (
                      <div className="col l4 s12">
                        <select
                          style={{
                            marginTop: "1rem",

                            border: "transparent",
                            background: "transparent",
                          }}
                          onChange={(e) => setDivision(e.target.value)}
                          className="browser-default  fabian_multiple_select"
                        >
                          <option disabled selected>
                            Division
                          </option>

                          {Uganda.filter((item) => {
                            if (item.district === district) {
                              return item;
                            } else {
                              return null;
                            }
                          }).map((item) => {
                            if (item.divisions.length > 0) {
                              return item.divisions.map((division, index) => {
                                return (
                                  <option key={index} value={division}>
                                    {division}
                                  </option>
                                );
                              });
                            } else {
                              return null;
                            }
                          })}
                        </select>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <select
                      style={{
                        textTransform: "capitalize",
                      }}
                      onChange={(e) => setOrderFormat(e.target.value)}
                      className="browser-default  fabian_multiple_select text-white btn  btn-small levbitz_top_main_filter"
                    >
                      <option value="" disabled selected>
                        Date Posted
                      </option>
                      {AdAge.map((age) => {
                        return (
                          <option
                            style={{
                              textTransform: "capitalize",
                            }}
                            key={age.id}
                            className="text-white"
                            value={age.value}
                          >
                            {age.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col l9">
                <div>{noDataFound ? "No data" : ""}</div>
                <div>
                  {result && result.length > 0 ? (
                    result
                      .filter((item) => {
                        if (district === "") {
                          return item;
                        } else if (division === "") {
                          return item.data.district === district;
                        } else {
                          return (
                            item.data.district === district &&
                            item.data.division === division
                          );
                        }
                      })
                      .map((item) => {
                        return <ThumbNailSilders item={item} />;
                      })
                  ) : (
                    <>
                      <p>no data</p>
                      <h4>Try to filter buy you Distict</h4>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {loading ? (
                <>
                  <div className="center">
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Loading..
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <img src={LaodingImg} alt="" />
                    </h3>
                  </div>
                </>
              ) : collectionIsEmpty ? (
                <NoMoreData />
              ) : (
                <>
                  <p className="center">
                    <span
                      style={{
                        background: "coral",
                        padding: "10px",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={LoadMoreHandeler}
                    >
                      Load More Ads
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default MegaSearch;
