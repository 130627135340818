import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
// import Navbar from "../../components/Navbar/Navbar";
// import { RiVipCrown2Fill } from "react-icons/ri";
// import { RiVipCrownFill } from "react-icons/ri";

import "./Promote.css";
import { db } from "../../lib/Firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
//import NavbarWrap from "../../components/Navbar/NavbarWrap";
import NavBarTwo from "../../Business/BusinessComponents/NavBarTwo/NavBarTwo";

function Promote() {
  const { closeSubmenu } = useGlobalContext();
  const [item, setItem] = useState("");

  const [showAd, setShowAd] = useState(true);

  const navigate = useNavigate();

  const { id } = useParams();
  // console.log(useParams());

  useEffect(() => {
    const docRef = doc(db, "ads", id);
    getDoc(docRef).then((doc) => {
      //console.log(doc.data().title);
      setItem(doc.data());
      setItem({
        id: doc.id,
        title: doc.data().title,
        author: doc.data().author,
      });
    });
  }, [id]);

  ///console.log(item);

  const FreeAdHandler = () => {
    const docRef = doc(db, "ads", id);
    updateDoc(docRef, {
      active: true,
      promotionType: "Free Ad",
    }).then(() => {
      alert("Ad promoted successfully As Free Ad");
      navigate("/");
    });
  };

  const basicAdPrice = 400;
  const feturedAdPrice = 800;
  const vipAdPrice = 1600;

  // var formatter = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",

  //   // These options are needed to round to whole numbers if that's what you want.
  //   minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //   //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  // });

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_kjBUyaqhzBHrwI",
      currency: "USD",
      amount: amount * 100,
      name: " India 364",
      description: "Thanks for purchasing",
      image: "https://uganto.com/static/media/loga.0f9231909380368edbef.png",

      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert("Payment Successfully");
      },
      prefill: {
        name: "Uganto India",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      <NavBarTwo />
      <div className="promo_showcase">
        <div className="container">
          <div className="row">
            <div className="col l5"></div>
            <div className="col l7">
              <div>
                <h3>Choose from our generous tiers</h3>
                <p className="white-text">
                  The higher the tier, the better the chance of your ad getting
                  high convesions and higher visibility.
                </p>
                <div>
                  <Link
                    style={{
                      backgroundColor: "#094c59",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                    to="/"
                  >
                    Read more{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onMouseOver={closeSubmenu}>
        <div className="activation_wrapper">
          <div className="container white">
            <div className="row">
              <div className="col l7">
                <div className="row">
                  <div className="col l6 s12">
                    <div
                      onClick={() => displayRazorpay(vipAdPrice)}
                      className="red price_selection_wrap price_card"
                    >
                      <h4>Vip</h4>
                      <h5>Unlimited Enqiries</h5>
                      <h5>32,000 UgX for 3 month</h5>
                    </div>
                  </div>

                  <div className="col l6 s12">
                    <div
                      onClick={() => displayRazorpay(feturedAdPrice)}
                      className="price_selection_wrap price_card"
                    >
                      <h4>Featured Ad</h4>
                      <h5>Unlimited Enqiries</h5>
                      <h5>28000 Ugx for 2 month</h5>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col l6 s12">
                    <div
                      onClick={() => displayRazorpay(basicAdPrice)}
                      className="price_selection_wrap price_card"
                    >
                      <h4>Basic Ad</h4>
                      <h5>Unlimited Enqiries</h5>
                      <h5>1000Ugx for one month</h5>
                    </div>
                  </div>

                  <div className="col l6 s12">
                    <div
                      onClick={FreeAdHandler}
                      className="red price_selection_wrap freeAdd price_card"
                    >
                      <h4>Free Add</h4>
                      <h5>Unlimited Enqiries</h5>
                      <h5>0.00 ugx /Month</h5>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    onClick={() => navigate("/profile")}
                    className="col l12 s12"
                  >
                    <div
                      style={{
                        backgroundColor: "#094c59",
                        padding: "10px 0px",
                        borderRadius: "5px",
                      }}
                    >
                      <h5 className="center white-text">Activate Later</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col l5">
                <div>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ug-b2b.appspot.com/o/resources%2Fpp.png?alt=media&token=81536e24-5f55-4042-9db2-ee24a9416e6f"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAd ? (
        <div
          className="levbitz_container"
          style={{
            marginBottom: "120px",
          }}
        >
          <small
            style={{
              cursor: "pointer",
            }}
            className="right red-text"
            onClick={() => setShowAd(false)}
          >
            Close Ad
          </small>
          <Link to="/">
            <img
              width={"100%"}
              style={{
                borderRadius: "20px",
                height: "auto",
              }}
              src="
        https://condescending-wozniak-6a12cc.netlify.app/static/media/add.fff84a32.gif
        
          "
              alt=""
            />
          </Link>
        </div>
      ) : null}
    </>
  );
}

export default Promote;
