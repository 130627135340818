import { FaCreditCard, FaBook, FaBriefcase } from "react-icons/fa";
import React from "react";
const sublinks = [
  {
    page: "Vehicles",

    categoryUrl: "vehicles",
    img: "https://assets.jiji.ug/art/attributes/categories/vehicles.png",
    links: [
      {
        label: "cars",
        subCategoryUrl: "cars",
        icon: <FaCreditCard />,
        url: "/mega?page=Vehicles&categoryUrl=vehicles&label=cars&subCategoryUrl=cars",
      },
      {
        label: "Buses And Minibuses",
        subCategoryUrl: "buses_and_minibuses",
        icon: <FaCreditCard />,
        url: "/mega/Vehicles/vehicles/buses_and_minibuses",
      },
      {
        label: "Trucks",
        subCategoryUrl: "trucks",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Trailers",
        subCategoryUrl: "trailers",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      // {
      //   label: "Motorcycles And Scooters",
      //   subCategoryUrl: "motorcycles_and_scooters",
      //   icon: <FaCreditCard />,
      //   url: "/mega/page",
      // },
      {
        label: "Vans And taxis",
        subCategoryUrl: "vans_and_taxis",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Pickups",
        subCategoryUrl: "pickups",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Tractors",
        subCategoryUrl: "tractors",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Vehicle Parts And Accessories",
        subCategoryUrl: "vehicle_parts_and_accessories",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      // {
      //   label: "Bike Parts and accessories",
      //   subCategoryUrl: "bike_parts_and_accessories",
      //   icon: <FaCreditCard />,
      //   url: "/mega/page",
      // },
    ],
  },
  {
    page: "Properties",
    dbUrl: "properties",
    categoryUrl: "properties",
    img: "https://assets.jiji.ug/art/attributes/categories/real-estate.png",
    links: [
      {
        label: "Houses For Sale",
        subCategoryUrl: "houses_for_sale",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Apartments For Sale",
        subCategoryUrl: "apartments_for_sale",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Houses For Rent",
        subCategoryUrl: "houses_for_rent",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Apartments For Rent",
        subCategoryUrl: "apartments_for_rent",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Land And Plots For Sale",
        subCategoryUrl: "land_and_plots_for_sale",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Land And Plots For Rent",
        subCategoryUrl: "land_and_plots_for_rent",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Commercial Property for Rent",
        subCategoryUrl: "commercial_property_for_rent",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Commercial Property for Sale",
        subCategoryUrl: "commercial_property_for_sale",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Event Centres, Venues And Workstations",
        subCategoryUrl: "event,_centres_venues_workstations",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Electronics",
    categoryUrl: "electronics",
    dbUrl: "electronics",
    img: "https://assets.jiji.ug/art/attributes/categories/electronics.png",
    links: [
      {
        label: "Laptops And Computers",
        subCategoryUrl: "laptops_and_computers",
        icon: <FaBook />,
        url: "/mega/page",
      },
      {
        label: "TVs And DVD Equipments",
        subCategoryUrl: "tvs_and_dvd_equipments",

        icon: <FaBook />,
        url: "/mega/page",
      },
      {
        label: "Audio and Music Equipments",
        subCategoryUrl: "smartphones",

        icon: <FaBook />,
        url: "/mega/page",
      },
      {
        label: "Computers  Accessories",
        subCategoryUrl: "computers_accessories",

        icon: <FaBook />,
        url: "/mega/page",
      },

      // {
      //   label: "Computers  Monitors",
      //   subCategoryUrl: "computers_monitors",

      //   icon: <FaBook />,
      //   url: "/mega/page",
      // },
    ],
  },

  {
    page: "Agriculture",
    dbUrl: "agriculture",
    img: "https://assets.jiji.ug/art/attributes/categories/agriculture.png",
    categoryUrl: "agriculture",
    links: [
      {
        label: "LiveStock",
        subCategoryUrl: "livestock",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Poultry",
        subCategoryUrl: "poultry",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Food",
        subCategoryUrl: "food",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Cash Crops",
        subCategoryUrl: "cash_crops",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Vegetables",
        subCategoryUrl: "vegetables",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Veterinary",
        subCategoryUrl: "veterinary",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Fruits",
        subCategoryUrl: "fruits",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Seeds",
        subCategoryUrl: "seeds",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Farm Labour",
        subCategoryUrl: "farm_labour",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },

      {
        label: "Animal Feeds",
        subCategoryUrl: "animal_feeds",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Poultry Feeds",
        subCategoryUrl: "poultry_feeds",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Fertilizers",
        subCategoryUrl: "fertilizers",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Pesticides",
        subCategoryUrl: "pesticides",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Farm Equipment",
        subCategoryUrl: "farm_equipment",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Phones And Tablets",
    dbUrl: "phones_and_tablets",
    categoryUrl: "phones_and_tablets",
    img: "https://assets.jiji.ug/art/attributes/categories/mobile.png",
    links: [
      {
        label: "Phones",
        subCategoryUrl: "phones",

        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Tablets",
        subCategoryUrl: "tablets",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Phones  Accessories",
        subCategoryUrl: "phones_accessories",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Tablet  Accessories",
        subCategoryUrl: "tablet_accessories",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Bikes And Scooters",
    dbUrl: "bikes_and_scooters",
    categoryUrl: "bikes_and_scooters",
    img: "https://assets.jiji.ug/art/attributes/categories/vehicles/moto.png",

    links: [
      {
        label: "Bikes",
        subCategoryUrl: "bikes",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Scooters",
        subCategoryUrl: "scooters",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Three Wheelers",
        subCategoryUrl: "three_wheelers",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Motorcycles ",
        subCategoryUrl: "motorcycles",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Bikes And Scooters Accessories",
        subCategoryUrl: "bikes_and_scooters_accessories",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Services",
    dbUrl: "services",
    categoryUrl: "services",
    img: "https://assets.jiji.ug/art/attributes/categories/services.png",

    links: [
      // {
      //   label: "Logistics Services",
      //   subCategoryUrl: "logistics_services",
      //   icon: <FaCreditCard />,
      //   url: "/mega/page",
      // },
      {
        label: "Legal Services",
        subCategoryUrl: "legal_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Security Services",
        subCategoryUrl: "security_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Beauty Services",
        subCategoryUrl: "beauty_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Electrical Services",
        subCategoryUrl: "electrical_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Building Services",
        subCategoryUrl: "building_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Plumbing Services",
        subCategoryUrl: "plumbing_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Cleaning Services",
        subCategoryUrl: "cleaning_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      // {
      //   label: "Tailoring Services",
      //   subCategoryUrl: "tailoring_services",
      //   icon: <FaCreditCard />,
      //   url: "/mega/page",
      // },
      // {
      //   label: "Gardening Services",
      //   subCategoryUrl: "gardening_services",
      //   icon: <FaCreditCard />,
      //   url: "/mega/page",
      // },
      {
        label: "Furniture Services",
        subCategoryUrl: "furniture_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Software Services",
        subCategoryUrl: "software_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Computer Services",
        subCategoryUrl: "computer_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      // {
      //   label: "Graphics Design",
      //   subCategoryUrl: "graphics_design",
      //   icon: <FaCreditCard />,
      // },
      // {
      //   label: "Web Design",
      //   subCategoryUrl: "web_design",
      //   icon: <FaCreditCard />,
      // },
      {
        label: "Marketing Services",
        subCategoryUrl: "marketing_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Event Services",
        subCategoryUrl: "event_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Photography Services",
        subCategoryUrl: "photography_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
      {
        label: "Catering Services",
        subCategoryUrl: "catering_services",
        icon: <FaCreditCard />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Fashion",
    dbUrl: "fashion",
    categoryUrl: "fashion",
    img: "https://assets.jiji.ug/art/attributes/categories/fashion.png",
    links: [
      {
        label: "Women Clothing",
        subCategoryUrl: "Women Clothing",

        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Men Clothing",
        subCategoryUrl: "Men Clothing",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Bag",
        subCategoryUrl: "Bags",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Travel Bags",
        subCategoryUrl: "travel_bags",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Kid products",
        subCategoryUrl: "_kid_products",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Women Shoes",
        subCategoryUrl: "women_shoes",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Women Beauty Products",
        subCategoryUrl: "women_beauty_products",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },

  {
    page: "Construction",
    dbUrl: "construction",
    categoryUrl: "construction",
    img: "https://assets.jiji.ug/art/attributes/categories/repair.png",
    links: [
      {
        label: "Cement",
        subCategoryUrl: "cement",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Doors And Windows",
        subCategoryUrl: "doors_and_windows",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Sand And stones",
        subCategoryUrl: "sand_and_stones",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Timber And Wood",
        subCategoryUrl: "timber_and_wood",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Health",
    dbUrl: "health",
    categoryUrl: "health",
    img: "https://assets.jiji.ug/art/attributes/categories/beauty.png",
    links: [
      {
        label: "Herbals",
        subCategoryUrl: "herbals",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Medical Equipments",
        subCategoryUrl: "medical_equipments",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Medicine",
        subCategoryUrl: "medicine",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },
  {
    page: "Home Appliances",
    dbUrl: "home_appliances",
    categoryUrl: "home_appliances",
    img: "https://assets.jiji.ug/art/attributes/categories/home.png",
    links: [
      {
        label: "Kitchen Appliances",
        subCategoryUrl: "kitchen_appliances",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Home Furnishing",
        subCategoryUrl: "home_furnishing",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Home Decor",
        subCategoryUrl: "home_decor",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Home Cleaning",
        subCategoryUrl: "home_cleaning",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Home Security",
        subCategoryUrl: "home_security",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Bathroom Appliances",
        subCategoryUrl: "bathroom_appliances",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
      {
        label: "Bedroom Appliances",
        subCategoryUrl: "bedroom_appliances",
        icon: <FaBriefcase />,
        url: "/mega/page",
      },
    ],
  },
];

export default sublinks;

export const AdAge = [
  {
    id: 1,
    name: "New To Old ",
    value: "desc",
  },
  {
    id: 2,
    name: "Old To New ",
    value: "asc",
  },
];
