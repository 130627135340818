import React from "react";

const AdImg = ({ src }) => {
  return (
    <div>
      <img
        style={{
          height: 160,
          objectFit: "contain",
        }}
        className="responsive-img"
        src={src}
        alt="alt"
      />
    </div>
  );
};

export default AdImg;
