import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./lib/context/GlobalContext/GlobalContext";
import { HelmetProvider } from "react-helmet-async";
import Loading from "./Pages/Loading/Loading";
import AuthProvider from "./lib/context/AuthContext/auth";

const root = createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loading />}>
    <HelmetProvider>
      <React.StrictMode>
        <AuthProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </AuthProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
