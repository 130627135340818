import React from "react";

function NoMoreData() {
  return (
    <div>
      <h3 className="center"> Opps No more data</h3>
    </div>
  );
}

export default NoMoreData;
