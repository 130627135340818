import React from "react";
import "./LoadMoreAdsButton.css";

function LoadMoreAdsButton({ onClick }) {
  return (
    <p className="center">
      <span className="originalAccount" onClick={onClick}>
        Load More Ads
      </span>
    </p>
  );
}

export default LoadMoreAdsButton;
