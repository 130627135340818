import React, { useState, useEffect } from "react";
import NavBarTwo from "../../BusinessComponents/NavBarTwo/NavBarTwo";
import "./SingleJob.css";
import { JobList } from "../../../lib/JobList/JobList";
import { useParams, Link } from "react-router-dom";
import { FcAlarmClock } from "react-icons/fc";
import { Helmet } from "react-helmet-async";

function SingleJob() {
  const { id } = useParams();

  const [selectedJob, setSelectedJob] = useState("");

  useEffect(() => {
    const job = JobList.find((job) => job.id === id);
    setSelectedJob(job);
  });

  console.log(selectedJob);

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{selectedJob.title}</title>

        <link rel="canonical" href={`/single_job/${id}`} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        <meta name="description" content={selectedJob.description} />
        <meta property="og:description" content={selectedJob.description} />
        <meta property="og:title" content={selectedJob.title} />
      </Helmet>
      <NavBarTwo />
      <div className="single_job_showcase_wrap">
        <div className="container">
          <div className="row">
            <div className="col l8 ">
              <h2 className="white-text single_job_title">
                {selectedJob.title}
              </h2>

              <div className="row hide-on-med-and-down">
                <div className="col l6">
                  <p className="white-text">Category</p>
                  <p className="white-text">{selectedJob.category}</p>
                </div>
                <div className="col l6">
                  <p className="white-text">Location</p>
                  <p className="white-text">{selectedJob.location}</p>
                </div>
              </div>
            </div>
            <div className="col l4 s12 ">
              <div className="top_apply_btn">
                <div
                  style={{
                    margin: "10px 0px",
                  }}
                >
                  <Link
                    to={`/apply/${selectedJob.id}/${selectedJob.title}`}
                    className="btn red"
                  >
                    {" "}
                    Apply Now
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => alert("this your is currently internal ")}
                    className="btn blue"
                  >
                    {" "}
                    Apply On Linkedin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row ">
          <div className="col l9">
            <div>
              <div
                style={{
                  padding: 10,
                  borderLeft: "2px solid rgb(9, 76, 89)",
                  borderRadius: "5px",
                }}
                className="row white"
              >
                <h5>Job Information</h5>
                <div className="col l4 s4">
                  <div>
                    <small>Category</small>
                    <p>{selectedJob.category}</p>
                  </div>

                  <div>
                    <small>Openings</small>
                    <p>{selectedJob.openings}</p>
                  </div>
                </div>
                <div className="col l4 s4">
                  <div>
                    <small>Type</small>
                    <p>{selectedJob.type}</p>
                  </div>

                  <div>
                    <small>Openings</small>
                    <p>{selectedJob.location}</p>
                  </div>
                </div>
                <div className="col l4 s4">
                  <div>
                    <small>Posted On</small>
                    <p>{selectedJob.posted}</p>
                  </div>
                  <div>
                    <small>Exprience</small>
                    <p>{selectedJob.exprience}</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                }}
                className="row white"
              >
                <div className="col l12">
                  <h5>Job Description</h5>

                  {!selectedJob.description
                    ? "details will be available soon"
                    : selectedJob.description
                        .toString()
                        .split(".")
                        .map((item, index) => {
                          return (
                            <p
                              style={{
                                margin: "0px 0px 10px 0px",
                              }}
                              key={index}
                              className="summaryTwo"
                            >
                              {item}.
                            </p>
                          );
                        })}

                  <h5>Responsibilities</h5>
                  <ul>
                    {!selectedJob.responsibities
                      ? "details will be available soon"
                      : selectedJob.responsibities
                          .toString()
                          .split(".")
                          .map((item, index) => {
                            return (
                              <li key={index} className="summaryTwo">
                                {item}
                              </li>
                            );
                          })}
                  </ul>

                  <h5>What We Are Looking For:</h5>
                  <ul>
                    {!selectedJob.lookingFor
                      ? "details will be available soon"
                      : selectedJob.lookingFor
                          .toString()
                          .split(".")
                          .map((item, index) => {
                            return (
                              <li key={index} className="summaryTwo">
                                {item}
                              </li>
                            );
                          })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col l3 s12 ">
            <div className="deadline_wrap">
              <div>
                <FcAlarmClock size={40} />
              </div>
              <div
                style={{
                  marginLeft: 40,
                }}
              >
                <p
                  style={{
                    margin: "0px",
                  }}
                >
                  deadline
                </p>{" "}
                <p
                  style={{
                    margin: "0px",
                    color: "#fff",
                  }}
                >
                  skjrrureuiui
                </p>
              </div>
            </div>

            <Link to={`/apply/${selectedJob.id}/${selectedJob.title}`}>
              <p
                style={{
                  backgroundColor: "#ff4848",
                  padding: "5px 10px",
                  marginBottom: 20,
                  borderRadius: 4,
                }}
                className="white-text center"
              >
                Equire about this job
              </p>
            </Link>

            <div
              style={{
                padding: "10px",
              }}
              className="white"
            >
              <p>Job Tags</p>

              <div>
                {selectedJob.skills
                  ? selectedJob.skills
                      .toString()
                      .split(",")
                      .map((item, index) => {
                        return (
                          <span key={index} className="single_job_tag">
                            #{item}
                          </span>
                        );
                      })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleJob;
