export const Uganda = [
  {
    district: "Kajiado",
    divisions: [  "Kitengela",
    "Ngong",
  "Ongata Rongai",
 "Bisil",
 "DalaleKutuk",
 "Emurua Dikir",
 "Entonet-Lenkisim",
 "Eselenkei",
"Ewaso Kendong",
 "Ewuaso Oonkidong'I",
 "Ildamat",
 "Iloodokilani-Amboseli",
 "Imaroro",
 "Isinya",
 "Kajiado CBD",
 "Kenyawa-Poka",
 "Kimana",
 "Kisaju",
 "Kiserian",
 "Kuku",
 "Kumpa",
 "Loitoktok",
 "Magadi",
 "Mata",
 "Matapato",
 "Mbirikani",
 "Mosiro",
 "Namanga",
 "Olkeri",
 "Oloika",
 "Oloolua",
 "Oloosirkon-Sholinke",
 "Purko",
 "Rombo"],
  },
  {
    district: "Kiambu",
    divisions: [ "Juja",

    "Kiambu",

    "Kikuyu",

    "Ruiru",

    "Thika",

    "Banana",

    "Gachie",

    "Gatundu North",

    "Gatundu South",

    "Gitaru",

    "Githunguri",

    "Kabete",

    "Kiambaa",

    "Lari",

    "Limuru",

    "Nachu",

    "Ndenderu",

    "Nyadhuna",

    "Rosslyn",

    "Ruaka",

    "Turitu",

    "Witeithie"],
  },
  {
    district: "Mombasa",
    divisions: [  "Kisauni",

    "Mombasa CBD",

    "Mvita",

    "Nyali",

    "Tudor",

    "Bamburi",

    "Chaani",

    "Changamwe",

    "Ganjoni",

    "Industrial Area (Msa)",

    "Jomvu",

    "Kikowani",

    "Kizingo",

    "Likoni",

    "Makadara (Msa)",

    "Mbaraki",

    "Old Town",

    "Shanzu",

    "Shimanzi",

    "Tononoka"],
},
  {
    district: "Nairobi",
    divisions: [ "Embakasi",

    "Karen",

    "Kilimani",

    "Nairobi Central",

    "Ngara",

    "Airbase",

    "Baba Dogo",

    "California",

    "Chokaa",

    "Clay City",

    "Dagoretti",

    "Dandora",

    "Donholm",

    "Eastleigh",

    "Gikomba",
    "Kamukunji",

    "Githurai",

    "Huruma",

    "Imara Daima",

    "Industrial Area Nairobi",

    "Jamhuri",

    "Kabiro",

    "Kahawa",

    "Kahawa West",

    "Kamulu",

    "Kangemi",

    "Kariobangi",

    "Kasarani",

    "Kawangware",

    "Kayole",

    "Kiamaiko",

    "Kibra",

    "Kileleshwa",

    "Kitisuru",

    "Komarock",

    "Landimawe",

    "Langata",

    "Lavington",

    "Lucky Summer",

    "Makadara",

    "Makongeni",

    "Maringo",
    "Hamza",

    "Mathare Hospital",

    "Mathare North",

    "Mbagathi Way",

    "Mlango Kubwa",

    "Mombasa Road",

    "Mountain View",

    "Mowlem",

    "Muthaiga",

    "Mwiki",

    "Nairobi South",

    "Nairobi West",

    "Njiru",

    "Pangani",

    "Parklands",
    "Highridge",

    "Pumwani",

    "Ridgeways",

    "Roysambu",

    "Ruai",

    "Ruaraka",

    "Runda",

    "Saika",

    "South B",

    "South C",

    "Thome",

    "Umoja",

    "Upperhill",

    "Utalii",

    "Utawala",

    "Westlands",

    "Woodley",
    "Kenyatta Golf Course",

    "Zimmerman",

    "Ziwani",
   "Kariokor"],
},
  {
    district: "Nakuru",
    divisions: [
                "Naivasha",
                "Nakuru Town East",
               "Bahati",
                "Gilgil",
                "Hells Gate",
                "Kuresoi North",

                    "Kuresoi South",

                    "Lanet",

                    "London",

                    "Maiella",

                    "Malewa West",

                    "Mbaruk",
                    "Eburu",

                    "Molo",

                    "Nakuru Town West",

                    "Njoro",

                    "Olkaria",

                    "Rongai",

                    "Salgaa",

                    "Subukia"]

  },
  {
    district: "Baringo",
    divisions: [

                    "Kabarnet",

                    "Marigat",

                    "Ravine",

                    "Bartabwa",

                    "Barwessa",

                    "Churo",
                    "Amaya",

                    "Emining",

                    "Ewalel Chapchap",

                    "Kapropita",

                    "Kisanana",

                    "Koibatek",

                    "Lembus",

                    "Lembus Kwen",

                    "Lembus",
                    "Perkerra",

                    "Mogotio",

                    "Mukutani",

                    "Nginyang East",

                    "Nginyang West",

                    "Sacho",

                    "Silale",

                    "Tenges",

  ],
  },
  {
    district: "Bomet",
    divisions: [

                    "Chemagel",

                    "Longisa",

                    "Silibwet Township",

                    "Boito",

                    "Chebunyo",

                    "Embomos",

                    "Kapletundo",

                    "Kembu",

                    "Kimulot",

                    "Kipsonoi",

                    "Merigi",

                    "Mogogosiek",

                    "Mutarakwa",

                    "Ndanai",
                    "Abosi",

                   "Ndaraweta",

                    "Nyangores",

                    "Sigor",

                    "Singorwet",

                    "Siongiroi"


  ],
  },
  {
    district: "Bungoma",
    divisions: [
                    "Kabucha(Chwele)",

                    "Khalaba (Kanduyi)",

                    "Marakaru(Tuuti)",

                    "Township D",

                    "Bokoli",

                    "Bukembe East",

                    "Bukembe West",

                    "Bumula",

                    "Bwake(Luuya)",

                    "Cheptais",

                    "Chepyuk",

                    "East Sang'Alo",

                    "Elgon",

                    "Kabula",

                    "Kamukuywa",

                   "Kibingei",

                    "Kimaeti",

                    "Kimilili",

                    "Maeni",

                    "Malakisi(South Kulisiru)",

                    "Maraka",

                    "Matulo",

                    "Mihuu",

                    "Misikhu",

                    "Musikoma",

                    "Namwela",

                    "Ndalu(Tabani)",

                    "Siboti",

                    "Sirisia",

                    "Sitikho",

                    "South Bukusu",

                    "Soysambu(Mitua)",

                    "Tongaren",

                    "Webuye",

                    "West Bukusu",

                    "West Nalondo",

                    "West Sang'Alo"
                ],
  },
  {
    district: "Busia",
    divisions: [
                   " Ageng'A Nanguba",

                    "Bunyala West (Budalangi)",

                    "Burumba",

                    "Amukura Central",

                    "Amukura East",

                    "Amukura West",

                   "Ang'Orom",

                    "Bukhayo Central",

                    "Bukhayo East",

                    "Bukhayo West",

                    "Bunyala Central (Budalangi)",

                    "Bunyala North",

                    "Busibwabo",

                    "Bwiri",

                    "Elugulu",

                    "Kingandole",

                    "Malaba Central",

                    "Malaba North",

                    "Marachi Central",

                    "Marachi East",

                    "Marachi West",

                    "Matayos South",

                    "Mayenje",

                    "Nambale Township",

                    "Nangina"

                ],
  },
  {
    district: "Elgeyo-Marakwet",
    divisions: [
    "Arror",

    "Chepkorio",

    "Emsoo",

    "Endo",

    "Kamariny",

    "Kapchemutwa",

    "Kaptarakwa",

    "Metkei",

    "Moiben(Kuserwo)",

    "Sengwer",

    "Soy North",

    "Tambach"
],
  },

  {
    district: "Embu",
    divisions: [
                    "Central Ward",

                    "Kirimari",

                    "Mwea",

                    "Evurore",

                    "Gaturi North",


                    "Gaturi South",


                    "Kagaari North",


                    "Kagaari South",


                    "Kiambere",


                    "Kithimu",


                    "Kyeni North",


                    "Kyeni South",


                    "Makima",


                    "Mavuria",


                    "Mbeti North",


                    "Mbeti South",


                    "Muminji",


                    "Nginda",


                    "Nthawa",


                    "Ruguru(Ngandori)"
                ],
  },
  {
    district: "Garissa",
    divisions: [
                    "Dadaab",

                    "Iftin",

                    "Abakaile",

                    "Bura",

                    "Damajale",

                    "Galbet",

                    "Goreale",

                    "Ijara",

                    "Jara Jara",

                    "Labasigale",

                    "Liboi",

                    "Masalani",

                    "Sankuri",

                    "Waberi"
                ],
  },
  {
    district: "Homa Bay",
    divisions: [
                    "Homa Bay Central",

                    "Mfangano Island",

                    "Central Karachuonyo",

                    "Central Kasipul",

                    "East Gem (Rangwe)",

                    "East Kamagak",

                    "Gembe",

                    "Homa Bay Arujo",

                    "Homa Bay East",

                    "Homa Bay West",

                    "Kabondo East",

                    "Kagan",

                    "Kaksingri West",

                    "Kanyadoto",

                    "Kanyaluo",

                    "Kanyamwa Kologi",

                    "Kanyamwa Kosewe",

                    "Kanyikela",

                    "Kasgunga",

                    "Kendu Bay Town",

                    "Kochia",

                    "Kwabwai",

                    "Lambwe",

                    "North Karachuonyo",

                    "Rusinga Island",

                   " South Kasipul",

                    "West Kamagak",

                    "West Kasipul",
              ],
  },
  {
    district: "Isiolo",
    divisions: [
    "Bulla Pesa",

    "Burat",

    "Garba Tulla",

    "Isiolo North",

    "Kinna",

    "Oldonyiro",

    "Wabera",

  ],
  },
  {
    district: "Kakamega",
    divisions: [
                    "Mumias Central",

                    "Sheywe",

                    "Bunyala Central (Navakholo)",

                    "Bunyala East",

                    "Bunyala West (Navakholo)",

                    "Butali(Chegulo)",

                    "Butsotso Central",

                    "Butsotso East",

                    "Butsotso South",

                    "Chekalini",

                    "Chemuche",

                    "Chevaywa",

                    "East Kabras",

                    "East Wanga",

                    "Etenje",

                    "Idakho Central",

                    "Idakho East",

                    "Idakho South",

                    "Ingostse-Mathia",

                    "Isukha Central",

                    "Isukha East",

                    "Isukha North",

                    "Isukha South",

                    "Isukha West",

                    "Khalaba (Matungu)",

                    "Kholera",

                    "Kisa Central",

                    "Kisa East",

                    "Kisa North",

                    "Kisa West",

                    "Kongoni",

                    "Koyonzo",

                    "Likuyani",

                    "Lugari",

                    "Lumakanda",

                    "Lusheya(Lubinu)",

                    "Lwandeti",

                    "Mahiakalo",

                    "Malaha(Isongo)(Makunga)",

                    "Marama Central",

                    "Marama North",

                    "Marama South",

                    "Marama West",

                    "Marenyo-Shianda",

                    "Mautuma",

                    "Mayoni",

                    "Murhanda",

                    "Musanda",

                    "Nzoia",

                    "Sango",

                    "Shinoyi-Shikomari-Esumeyia",

                    "Shirere",

                    "Shirugu-Mugai",

                    "Sinoko",

                    "South Kabras",

                    "West Kabras",
               ],
  },
  {
    district: "Kericho",
    divisions: [
                    "Ainamoi",

                   "Litein",

                    "Chaik",

                    "Cheboin",

                    "Chemosot",

                    "Cheplanget",

                    "Chepseon",

                    "Cheptororiet(Seretut)",

                    "Chilchila",

                    "Kabianga",

                    "Kamasian",

                    "Kapkatet",

                    "Kapkugerwet",

                    "Kapsaos (Ainamoi)",

                    "Kapsoit",

                    "Kapsuser",

                    "Kedowa(Kimugul)",

                    "Kipchebor",

                    "Kipchimchim",

                    "Kipkelion",

                    "Londiani",

                    "Sigowet",

                   "Soin (Sigowet)",

                    "Tebesonik",

                    "Waldai",
                ],
  },
  {
    district: "Kilifi",
    divisions: [
                    "Kilifi North",

                    "Malindi",

                    "Mtwapa",

                    "Ganze",

                    "Kaloleni",

                    "Kambe(Ribe)",

                    "Kilifi South",

                    "Kilifi Town",

                    "Magarini",

                    "Rabai",
                ],
  },
  {
    district: "Kirinyaga",
    divisions: [
                    "Kerugoya",

                    "Kiine",

                    "Baragwi",

                    "Gathigiriri",

                    "Inoi",

                    "Kabare",

                    "Kangai",

                    "Kanyekini",

                    "Kariti",

                    "Karumandi",

                    "Mukure",

                    "Murinduko",

                    "Mutira",

                    "Mutithi",

                    "Ngariama",

                    "Njukiini",

                    "Nyangati",

                    "Tebere",

                    "Thiba",

                    "Wamumu",
                ],
  },
  {
    district: "Kisii",
    divisions: [
                   " Kisii CBD",

                    "Basi Bogetaorio",

                    "Bobasi",

                    "Bomachoge Borabu",

                    "Bomachoge Chache",

                    "Bonchari",

                    "Kitutu Chache South",

                    "Marani",

                    "Monyerero",

                    "Nyaribari Chache",

                    "Nyaribari Masaba",

                    "Ogembo",

                    "South Mugirango",

                    "Suneka",
                ],
  },
  {
    district: "Kisumu",
    divisions: [
                    "Kisumu Central",

                    "Kisumu West",

                    "Chemelil",

                    "Kaloleni",

                    "Kisumu East",

                    "Kolwa Central",

                    "Kolwa East",

                    "Muhoroni",

                    "North West Kisumu",

                    "Nyakach",

                    "Nyando",

                    "Seme",

                    "South West Kisumu",
                ],
  },

  {
    district: "Kitui",
    divisions: [
                    "Central Mwingi",

                    "Township",

                    "Athi",

                    "Chuluni",

                    "Endau/Malalani",

                    "Ikanga/Kyatune",

                    "Kanyangi",

                    "Kauwi",

                    "Kiomo/Kyethani",

                    "Kisasi",

                    "Kwa Mutonga/Kithumula",

                    "Kwavonza/Yatta",

                    "Kyangwithya East",

                    "Kyangwithya West",

                    "Kyome/Thaana",
                "Kyuso",

                    "Matinyani",

                    "Mbitini",

                    "Migwani",

                    "Mulango",

                    "Mutito/Kaliku",

                    "Mutomo",

                    "Mutonguni",

                    "Ngomeni",

                    "Nguni",

                    "Nguutani",

                    "Nzambani",

                    "Tseikuru",

                    "Zombe(Mwitika)",
                ],
  },
  {
    district: "Kwale",
    divisions: [
                   " Ukunda",


                    "Chengoni(Samburu)",

                    "Dzombo",

                    "Gombato Bongwe",

                    "Kinango",

                    "Kinondo",

                    "Kubo South",

                    "Mackinnon Road",

                    "Mazeras",

                    "Mkongani",

                    "Mwavumbo",

                    "Mwereni",

                    "Ndavaya",

                    "Pongwe(Kikoneni)",

                    "Ramisi",

                    "Tiwi",

                    "Tsimba Golini",

                    "Vanga",

                    "Waa",
                ],
  },
  {
    district: "Laikipia",
    divisions: [
                    "Nanyuki",

                    "Githiga (Laikipia)",

                    "Laikipia Central",

                    "Laikipia East",

                    "Laikipia North",

                    "Laikipia West",

                    "Mukogondo East",

                    "Nyahururu",
                ],
  },
  {
    district: "Lamu",
    divisions: [
                    "Mkomani",

                    "Bahari",

                    "Faza",

                    "Hindi",

                    "Hongwe",

                    "Shella",

                    "Witu",
                ],
  },

  {
    district: "Machakos",

    divisions: [
                    "Athi River",

                    "Machakos Town",

                    "Syokimau",

                    "Kangundo",

                    "Kathiani",

                    "Lower Kaewa(Kaani)",

                    "Masinga",

                   " Matungulu",

                    "Mavoko",

                    "Mlolongo",

                    "Mua",

                    "Mwala",

                    "Upper Kaewa(Iveti)",

                    "Yatta",
                ],
  },
  {
    district: "Makueni",
    divisions: [
                    "Emali(Mulala)",

                    "Makindu",

                    "Wote",

                    "Ilima",

                    "Ivingoni(Nzambani)",

                    "Kasikeu",

                    "Kathonzweni",

                    "Kiima Kiu(Kalanzoni)",

                    "Kikumbulyu North",

                    "Kikumbulyu South",

                    "Kilungu",

                    "Kisau-Kiteta",

                    "Kithungo(Kitundu)",

                    "Masongaleni",

                    "Mavindini",

                    "Mbitini",

                    "Mbooni",

                    "Mtito Andei",

                    "Mukaa",

                    "Muvau(Kikuumini)",

                    "Nguu/Masumba",

                    "Nguumo",

                    "Nzaui(Kilili,Kalamba)",

                    "Thange",

                    "Tulimani",

                    "Ukia",

                    "Waia(Kako)",
                ],
  },
  {
    district: "Mandera",

    divisions: [
    "Elwak North",

    "Township"
],
  },
  { district: "Marsabit", divisions: [
  "Butiye",

  "Marsabit Central",

  "Moyale Township",
] },
  {
    district: "Meru",
    divisions: [
                    "Maua",

                    "Municipality",

                    "Abogeta East",

                    "Abogeta West",

                    "Abothuguchi Central",

                    "Akirang'Ondu",

                    "Akithii",

                    "Amwathi",

                    "Antubetwe Kiongo",

                    "Athiru Gaiti",

                    "Athiru Ruujine",

                    "Athwana",

                    "Igembe East",

                    "Igoji East",

                    "Igoji West",

                    "Kangeta",

                    "Karama",

                    "Kiagu",

                    "Kianjai",

                    "Kibirichia",

                    "Kiirua(Naari)",

                    "Kisima",

                    "Mbeu",

                    "Mikinduri",

                    "Mitunguu",

                    "Muthara",

                    "Mwanganthia",

                    "Nkomo",

                    "Nkuene",

                    "Ntima East",

                    "Ntima West",

                    "Nyaki East",

                    "Nyaki West",

                    "Ruiri(Rwarera)",

                    "Timau",
                ],
  },
  {
    district: "Migori",
    divisions: [
    "Bukira Centrl(Ikerege)",

    "Bukira East",

    "Central Kamagambo",

    "Central Kanyamkago",

    "Central Sakwa (Awendo)",

    "East Kamagambo",

    "East Kanyamkago",

    "God Jope",

    "Isibania",

    "Kachien'G A",

    "Kakrao",

    "Kwa",

    "Makerero",

    "Muhuru",

    "North Kadem",

    "North Kamagambo",

    "North Sakwa (Awendo)",

    "Ntimaru West",

    "Ragana-Oruba",

    "South Kamagambo",

    "South Sakwa (Awendo)",

    "Suna Central",

    "Tagare",

    "Wasweta II",

    "West Sakwa (Awendo)",
],
  },
  {
    district: "Murang'a",

    divisions: [
                    "Gatanga",
                
                    "Kimorori(Wempa)",
                
                    "Makuyu",
                
                    "Township G",
              
                    "Gaichanjiru",
                
                    "Gaturi",
                
                    "Gitugi",
                
                    "Ichagaki",
                
                    "Ithanga",
                
                    "Ithiru",
                
                    "Kagundu-Ini",
                
                    "Kahumbu",
                
                    "Kakuzi(Mitubiri)",
                
                    "Kamacharia",
                
                    "Kamahuha",
                
                    "Kambiti",
                
                    "Kangari",
                
                    "Kanyenya-Ini",
                
                    "Kariara",
                
                    "Kigumo",
                
                    "Kihumbu-Ini",
                
                    "Kinyona",
                
                    "Kiru",
                
                    "Mbiri",
                
                    "Mugoiri",
                
                    "Mugumo-Ini",
                
                    "Muguru",
                
                    "Muruka",
                
                    "Ng'Araria",
                
                    "Nginda",
                
                    "Ruchu",
                
                    "Rwathia",
                
                    "Wangu",
                ],
  },
  {
    district: "Nandi",

    divisions: [
                    "Kapsabet",
           
                    "Chemelil(Chemase)",
               
                    "Chemundu(Kapng'Etuny)",
               
                    "Chepkumia",
               
                    "Chepkunyuk",
               
                    "Kabisaga",
               
                    "Kabiyet",
               
                    "Kapkangani",
               
                    "Kaptel(Kamoiywo)",
               
                    "Kaptumo-Kaboi",
               
                    "Kemeloi-Maraba",
               
                    "Kilibwoni",
               
                    "Kipkaren",
               
                    "Kiptuya",
               
                    "Kobujoi",
               
                    "Kosirai",
               
                    "Nandi Hills",
               
                    "Ndalat",
               
                    "Ol'Lessos",
               
                    "Songhor(Soba)",
               
                    "Tindiret",
                ],
  },
  {
    district: "Narok",

    divisions: [
                    "Narok Town",
         
                    "Angata Barikoi",
                
                    "Ildamat (Narok)",
                
                    "Ilkerin",
                
                    "Ilmotiok",
                
                    "Kapsasian",
                
                    "Keekonyokie (Narok)",
                
                    "Keyian",
                
                    "Kilgoris Central",
                
                    "Kimintet",
                
                    "Lolgorian",
                
                    "Majimoto/Naroosura",
                
                    "Mara",
                
                    "Melelo",
                
                    "Melili",
                
                    "Mosiro (Narok)",
                
                    "Naikarra",
                
                    "Olokurto",
                
                    "Ololmasani",
                
                    "Ololulung'A",
                
                    "Olorropil",
                
                    "Olpusimoru",
                
                    "Siana",
                
                    "Suswa",
                ],
  },
  {
    district: "Nyamira",

    divisions: [
    "Bogichora",

    "Bokeira",

    "Bonyamatuta",

    "Esise",

    "Gachuba",

    "Gesima",

    "Kemera",

    "Kiabonyoru",

    "Magwagwa",

    "Manga",

    "Nyamaiya",

    "Nyansiongo",

    "Rigoma",

    "Township F"
],
  },
  {
    district: "Nyandarua",

    divisions: [
                    "Karau",
                
                    "Magumu",
                
                    "Central Ndaragwa",
                
                    "Charagita",
                
                    "Engineer",
                
                    "Gathanji",
                
                    "Gathara",
                
                    "Gatimu",
                
                    "Geta",
                
                    "Githabai",
                
                    "Githioro",
                
                    "Kaimbaga",
                
                    "Kanjuiri Range",
                
                    "Kipipiri",
                
                    "Kiriita",
                
                    "Leshau Pondo",
                
                    "Mirangine",
                
                    "Murungaru",
                
                    "NjabiniKiburu",
                
                    "North Kinangop",
                
                    "Nyakio",
                
                    "Rurii",
                
                    "Shamata",
                
                    "Wanjohi",
                
                    "Weru",
                ],
  },
  {
    district: "Nyeri",

    divisions: [
                    "Karatina Town",
                
                    "Dedan Kimanthi",
                
                    "Iriaini",
                
                    "Kieni",
                
                    "Kirimukuyu",
                
                    "Mathira",
                
                   " Mukurwe-Ini Central",
                
                    "Mukurwe-Ini West",
                
                    "Mukurweini",
                
                    "Naromoru Kiamathaga",
                
                    "Nyeri Town",
                
                    "Othaya",
                "Tetu",
                
                    "Thegu River",
                ],
  },
  {
    district: "Samburu",

    divisions: [
    "Angata Nanyokie",

    "Loosuk",

    "Maralal",

    "Suguta Marmar",

    "Wamba East",

    "Waso",
],
  },
  {
    district: "Siaya",

    divisions: [
                    "Siaya Township",
             
                    "Central Gem",
                
                    "Central Sakwa (Bondo)",
                
                    "East Asembo",
                
                    "East Gem",
                
                    "East Ugenya",
                
                    "North Gem",
                
                    "North Sakwa (Bondo)",
                
                    "North Ugenya",
                
                    "North Uyoma",
                
                    "Sidindi",
                
                    "Sigomere",
                
                    "South East Alego",
                
                    "South Gem",
                
                    "South Sakwa (Bondo)",
                
                    "Ugunja",
                
                    "Ukwala",
                
                    "West Alego",
                
                    "West Asembo",
                
                    "West Gem",
                
                    "West Sakwa (Bondo)",
                
                    "West Ugenya",
                
                    "West Uyoma",
                
                    "Yala Township",
                
                    "Yimbo West",
                ],
  },
  {
    district: "Taita Taveta",

    divisions: [
    "Bura (Mwatate)",

    
    "Chala",

    
    "Kaloleni",

    
    "Kasigau",

    
    "Mahoo",

    
    "Mata",

    
    "Mboghoni",

    
    "Mbololo",

    
    "Mwatate",

    
    "Ngolia",

    
    "Sagala",

    
    "Werugha",

    
    "Wumingu/Kishushe",

    
    "Wundanyi/Mbale",
],
  },
  {
    district: "Tana River",

    divisions: [
    "Bura",

    "Chewani",

    "Garsen Central",

    "Kinakomba",

    "Kipini East",

    "Kipini West",

    "Madogo",

    "Mikinduni",

    "Wayu",
],
  },
  {
    district: "Tharaka-Nithi",

    divisions: [
                    "Igambang'Ombe",
              
                    "Chiakariga",
                
                    "Chogoria",
                
                    "Ganga",
                
                    "Gatunga",
                
                    "Karingani",
                
                    "Magumoni",
                
                    "Mariani",
                
                    "Mitheru",
                
                    "Mugwe",
                
                    "Mukothima",
                
                    "Muthambi"
                ],
  },
  {
    district: "Trans-Nzoia",

    divisions: [
                   "Kitale",
            
                    "Bidii",
                
                    "Chepchoina",
                
                    "Chepsiro/Kiptoror",
                
                    "Cherangany/Suwerwa",
                
                    "Endebess",
                
                    "Hospital (Kiminini)",
                
                    "Kaplamai",
                
                    "Keiyo",
                
                    "Kiminini",
                
                    "Kinyoro",
                
                    "Kwanza",
                
                    "Makutano",
                
                    "Matisi",
                
                    "Motosiet",
                
                    "Nabiswa",
                
                    "Saboti",
                
                    "Sikhendu",
                
                    "Sinyerere",
                
                    "Sirende",
                
                    "Sitatunga",
                
                    "Tuwani",
                
                    "Waitaluk",
               ],
  },
  {
    district: "Turkana",

    divisions: [
                    "Lodwar Township",
             
                    "Kakuma",
                
                    "Kanamkemer",
                
                    "Katilia",
                
                    "Kerio Delta",
                
                    "Kibish",
                
                    "Lokichar",
                
                    "Lokichoggio",
                
                    "Turkwel",
                ],
  },
  {
    district: "Uasin Gishu",

    divisions: [
                    "Eldoret CBD",
         
                    "Ainabkoi",
                
                    "Kapseret",
                
                    "Kesses",
                
                    "Moiben",
                
                    "Soy",
                
                    "Turbo",
                ],
  },
  {
    district: "Vihiga",

    divisions: [
                    "Central Bunyore",
                
                    "Central Maragoli",
                
                    "Chavakali",
                
                    "Luanda Township",
             
                    "Banja",
                
                    "Emabungo",
                
                    "Luanda South",
                
                    "Lugaga-Wamuluma",
                
                    "Lyaduywa/Izava",
                
                    "Muhudu",
                
                    "Mwibona",
                
                    "North East Bunyore",
                
                    "North Maragoli",
                
                    "Shamakhokho",
                
                    "Shiru",
                
                    "Tambua",
                
                    "Wodanga",
                ],
  },
  {
    district: "Wajir",

    divisions: [
                    "Central Bunyore",
                
                    "Central Maragoli",
                
                    "Chavakali",
               
                    "Luanda Township",
              
                    "Banja",
                
                    "Emabungo",
                
                    "Luanda South",
                
                    "Lugaga-Wamuluma",
                
                    "Lyaduywa/Izava",
                
                    "Muhudu",
                
                    "Mwibona",
                
                    "North East Bunyore",
                
                    "North Maragoli",
                
                    "Shamakhokho",
                
                    "Shiru",
                
                    "Tambua",
                "Wodanga",
                ],
  },
  {
    district: "West Pokot",

    divisions: [
    "Endugh",

    "Kapenguria",

    "Lomut",

    "Mnagei",
],
  },
];






