import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../lib/context/GlobalContext/GlobalContext";
import sublinks from "../../../../lib/Data/Data";
import "./Sidebar.css";

import { AuthContext } from "../../../../lib/context/AuthContext/auth";
import { AiOutlineUserAdd } from "react-icons/ai";
const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useGlobalContext();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <div
      className={`${
        isSidebarOpen ? "sidebar-wrapper show" : "sidebar-wrapper"
      }`}
    >
      <aside className="sidebar">
        <button className="close-btn" onClick={closeSidebar}>
          <FaTimes />
        </button>
        <div className="sidebar-links">
          <div>
            <div className="row">
              {user ? (
                <>
                  <div className="col l6">
                    <div>
                      <span className="sidebar-mob-btn" onClick={closeSidebar}>
                        <Link className="white-text" to="/profile">
                          <AiOutlineUserAdd size={13} /> Dashbord
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col l4 s4">
                    <div>
                      <span className="sidebar-mob-btn" onClick={closeSidebar}>
                        <Link className="white-text" to="/sign_up">
                          Register
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="col l4 s4">
                    <div>
                      <span className="sidebar-mob-btn" onClick={closeSidebar}>
                        <Link className="white-text" to="/sign_in">
                          Login
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col l4 s4">
                <div>
                  <span className="sidebar-mob-btn red" onClick={closeSidebar}>
                    <Link className="white-text" to="/post_ad">
                      Post Ad
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {sublinks.map((item, index) => {
            const { links, page, categoryUrl } = item;
            return (
              <article key={index}>
                <div onClick={closeSidebar} className="mobo-sidebar-title">
                  <Link
                    style={{
                      padding: 5,
                    }}
                    className="white-text"
                    to={`/${categoryUrl}`}
                  >
                    {page}
                  </Link>
                </div>
                <div className="sidebar-sublinks">
                  {links.map((link, index) => {
                    const { url, icon, label, img, subCategoryUrl } = link;
                    return (
                      <Link
                        className="mobo-sidebar-link"
                        onClick={closeSidebar}
                        key={index}
                        to={`/mega/${categoryUrl}/${subCategoryUrl}`}
                      >
                        {label}
                      </Link>
                    );
                  })}
                </div>
              </article>
            );
          })}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
