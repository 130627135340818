import React from "react";
import { Link } from "react-router-dom";

function NavBarTwo() {
  return (
    <div className="navbar-fixed">
      <nav
        style={{
          background: "#094c59",
        }}
        className="z-depth-0"
      >
        <div className="container">
          <div className="nav-wrapper">
            <Link to="/" className="brand-logo left">
              bantuMart
            </Link>
            <ul id="nav-mobile" className="right ">
              <li className="hide-on-med-and-down">
                <Link to="/jobs">Careers</Link>
              </li>
              <li className="hide-on-med-and-down">
                <Link to="/sign_up">register</Link>
              </li>
              <li>
                <Link to="/post_ad">Post Ad</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBarTwo;
