import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../../lib/CaroselSetting/CaroseSetting";

import { Link } from "react-router-dom";

export const Sdata = [
  {
    id: 1,
    title: "Manikarnika Women Blue Embroidered Velvet Single Saree",
    desc: "Be an ultimate diva for every occasion in this dazzling saree! Make sure you turn some heads this upcoming festive season with our latest trends! Featuring gorgeous colors, durable and comfotable fabric, intricate designs and prints, be the ultimate ethnic queen in our amazing sarees and look pretty in our Designer styles! Add a mesmerizing look with this sari to your wardrobe and be more ethnic and Indian this season. Product colour may slightly vary due to photographic lighting sources or your monitor settings.",
    price: 2000,
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvxhghqqwq/manikarnika-women-blue-embroidered-velvet-single-saree-product-images-rvxhghqqwq-0-202202250651.jpg",
      "https://www.jiomart.com/images/product/600x750/rvxhghqqwq/manikarnika-women-blue-embroidered-velvet-single-saree-product-images-rvxhghqqwq-1-202202250651.jpg",
      "https://www.jiomart.com/images/product/600x750/rvxhghqqwq/manikarnika-women-blue-embroidered-velvet-single-saree-product-images-rvxhghqqwq-3-202202250652.jpg",
    ],
    bg: "#094c59",
    btnBg: "#ffe799",
  },
  {
    id: 2,
    title: "50% Off For Your First Shopping",
    desc: "Classic! That’s how one would describe you when you are dressed in a sports T-shirt and a pair of striped chinos. To complete this look, you can opt for a pair of white sports shoes or a pair of brown boat shoes.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rv16zkbobs/maanushi-saree-women-black-woven-design-art-silk-single-saree-product-images-rv16zkbobs-0-202201241449.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  {
    id: 3,
    title: "The World’s Most Comfortable Shoes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvreimi1bb/shivanya-fashion-women-green-floral-velvet-saree-product-images-rvreimi1bb-0-202202241249.jpg",
    ],
    bg: "#011e32",
    btnBg: "#ffe799",
  },
  {
    id: 4,
    title: "50% Off For Your First Shopping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rv5ahzp37u/shivanya-fashion-women-beige-floral-art-silk-saree-product-images-rv5ahzp37u-0-202202241248.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  //nect 5 -8
  {
    id: 5,
    title: "50% Off on Trendy watches for changing times",
    desc: "Make your time perfect with the perfect watch ,your wrist deserves the best thing to wear. Add one more to your expectation.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvzurttczu/pisara-woman-s-beige-woven-design-chanderi-silk-cotton-saree-product-images-rvzurttczu-0-202202262025.jpg",
    ],
    bg: "#094c59",
    btnBg: "#ffe799",
  },
  {
    id: 6,
    title: "50% Off For Your First Shopping",
    desc: "Classic! That’s how one would describe you when you are dressed in a sports T-shirt and a pair of striped chinos. To complete this look, you can opt for a pair of white sports shoes or a pair of brown boat shoes.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvdb0sjrkc/pisara-women-pink-woven-design-cotton-silk-saree-product-images-rvdb0sjrkc-0-202202261602.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  {
    id: 7,
    title: "The World’s Most Comfortable Shoes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvyt0istw3/manikarnika-women-orange-embroidered-velvet-single-saree-product-images-rvyt0istw3-0-202202250652.jpg",
    ],
    bg: "#011e32",
    btnBg: "#ffe799",
  },
  {
    id: 8,
    title: "50% Off For Your First Shopping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvba1vufzm/manikarnika-women-pink-embroidered-velvet-single-saree-product-images-rvba1vufzm-0-202202250648.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  //next 9 -12
  {
    id: 9,
    title: "50% Off on Trendy watches for changing times",
    desc: "Make your time perfect with the perfect watch ,your wrist deserves the best thing to wear. Add one more to your expectation.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rv9rhnnccj/shivanya-fashion-women-beige-floral-art-silk-saree-product-images-rv9rhnnccj-0-202202241246.jpg",
    ],
    bg: "#094c59",
    btnBg: "#ffe799",
  },
  {
    id: 10,
    title: "50% Off For Your First Shopping",
    desc: "Classic! That’s how one would describe you when you are dressed in a sports T-shirt and a pair of striped chinos. To complete this look, you can opt for a pair of white sports shoes or a pair of brown boat shoes.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvnlymnbfn/satyam-weaves-women-multicolor-woven-design-saree-product-images-rvnlymnbfn-0-202202262323.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  {
    id: 11,
    title: "The World’s Most Comfortable Shoes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rv4oxl1ne8/leeza-store-women-s-green-woven-aura-banarasi-silk-blend-plain-solid-golden-zari-border-saree-with-blouse-piece-product-images-rv4oxl1ne8-0-202202262014.jpg",
    ],
    bg: "#011e32",
    btnBg: "#ffe799",
  },
  {
    id: 12,
    title: "50% Off For Your First Shopping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvcz5jabxe/kuki-women-yellow-self-design-art-silk-saree-product-images-rvcz5jabxe-0-202203011001.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  //next 13 16
  {
    id: 13,
    title: "50% Off on Trendy watches for changing times",
    desc: "Make your time perfect with the perfect watch ,your wrist deserves the best thing to wear. Add one more to your expectation.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvvv8v2mur/pisara-woman-s-brown-woven-design-chanderi-cotton-saree-product-images-rvvv8v2mur-0-202203011103.jpg",
    ],
    bg: "#094c59",
    btnBg: "#ffe799",
  },
  {
    id: 14,
    title: "50% Off For Your First Shopping",
    desc: "Classic! That’s how one would describe you when you are dressed in a sports T-shirt and a pair of striped chinos. To complete this look, you can opt for a pair of white sports shoes or a pair of brown boat shoes.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvwwymswup/apnisha-women-s-lycra-half-half-saree-product-images-rvwwymswup-0-202202250519.jpg",
    ],
    bg: "#080713",
    btnBg: "#ffe799",
  },
  {
    id: 15,
    title: "The World’s Most Comfortable Shoes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvnfszrosc/apnisha-women-s-lycra-half-half-saree-product-images-rvnfszrosc-0-202202250518.jpg",
    ],
    bg: "#011e32",
    btnBg: "#ffe799",
  },
  {
    id: 16,
    title: "50% Off For Your First Shopping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    pdtImg: [
      "https://www.jiomart.com/images/product/330x410/rvmtocxqdr/apnisha-women-s-lycra-half-half-saree-product-images-rvmtocxqdr-0-202202250518.jpg",
    ],
    bg: "#080713gjggjg",
    btnBg: "#ffe799",
  },
  //next
];

function Products() {
  return (
    <>
      <div className="container">
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <h2
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            Welcome To my Saree Shop{" "}
          </h2>
          <p>order Now and get the best quality sarees at the best price</p>
        </div>

        <Slider {...settings}>
          {Sdata.map((item) => {
            return (
              <Link to={`/pd/${item.id}`} key={item.id}>
                <img className="responsive-img" src={item.pdtImg[0]} alt="" />
                <h2 className="center">{item.id}</h2>
              </Link>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default Products;
