import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";
import ThumbNailSilders from "../../components/ThumbNailSiders/ThumbNailSilders";
import { districts } from "../../lib/Data/Districts/Districts";
import { Uganda } from "../../lib/Data/Locations/Locations";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MegaCaroselsettings } from "../../lib/CaroselSetting/CaroseSetting";
import "../../Pages/MegaSearch/MegaSearch.css";
import LaodingImg from "../../lib/images/loading.png";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  deleteDoc,
  doc,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import NavbarWrap from "../../components/Navbar/NavbarWrap";

function BottomCategories() {
  const { page, categoryUrl, subCategoryUrl, category, district, subcategory } =
    useParams();

  console.log(useParams());
  const { closeSubmenu } = useGlobalContext();

  // const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);

  const [result, setResult] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,
      where("active", "==", true),
      where("category", "==", category),
      where("subcategory", "==", subcategory),
      where("district", "==", district),
      // where("subcategory", "==", subCategoryUrl),
      // where("district", "==", district),
      orderBy("createdAt", "desc"),
      limit(12)
    );
    onSnapshot(q, (snapshoot) => {
      if (snapshoot.size === 0) {
        alert("collection is empty");
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        // console.log(snapshoot.docs);
        setLoading(true);
        // setTimeout(() => {

        const term = snapshoot.docs.map((doc) =>
          // console.log(doc.data())
          ({
            id: doc.id,
            data: doc.data(),
          })
        );

        setResult(term);
        setLoading(false);
        //get the last doc
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
      }
    });
  }, [page, categoryUrl, subCategoryUrl]);

  console.log(result);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    // alert("Load more");

    setLoading(true);

    const q = query(
      NewData,
      where("active", "==", true),
      where("category", "==", category),
      where("subcategory", "==", subcategory),
      where("district", "==", district),

      orderBy("createdAt", "desc"),
      startAfter(lastDoc),

      limit(12)
    );

    getDocs(q).then((snapshoot) => {
      // setLoading(true);
      // setTimeout(() => {
      if (snapshoot.size === 0) {
        //  alert("No more data");
        setLoading(false);
        setCollectionIsEmpty(true);
        //setResults("no more data");
      } else {
        const term = snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setResult([...result, ...term]);
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <NavbarWrap />

      <div onMouseOver={closeSubmenu}>
        <div className="container ">
          <div className="mega_showcase_banner">
            <div>
              <h5 className="white-text">{page}</h5>
              <h5 className="white-text">{subCategoryUrl}</h5>

              <div className="">
                <div className="row">
                  <div className="col l4 s12">
                    <div
                      style={{
                        width: "300px",
                      }}
                    >
                      <select
                        style={{
                          // marginTop: "1rem",
                          // border: "transparent",
                          // background: "transparent",
                          height: "30px",
                          width: "200px",
                          textTransform: "lowercase",
                        }}
                        // onChange={(e) => setDistrict(e.target.value)}
                        className="browser-default  fabian_multiple_select text-white btn btn-small"
                      >
                        <option value="" disabled selected>
                          Select District
                        </option>
                        {Uganda.map((district) => {
                          return (
                            <option
                              className="text-white"
                              key={district.district}
                              value={district.district}
                            >
                              {district.district}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {/*district ? (
                  <div className="col l4 s12">
                    <select
                      style={{
                        marginTop: "1rem",
                        marginTop: "1rem",
                        border: "transparent",
                        background: "transparent",
                      }}
                      onChange={(e) => setDivision(e.target.value)}
                      className="browser-default  fabian_multiple_select"
                    >
                      <option disabled selected>
                        Division
                      </option>

                      {Uganda.filter((item) => {
                        if (item.district === district) {
                          return item;
                        } else {
                          return null;
                        }
                      }).map((item) => {
                        if (item.divisions.length > 0) {
                          return item.divisions.map((division, index) => {
                            return (
                              <option key={index} value={division}>
                                {division}
                              </option>
                            );
                          });
                        } else {
                          return null;
                        }
                      })}
                    </select>
                  </div>
                    ) : null*/}

                  <div className="col l4">
                    <div
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <button
                        // onClick={() => {
                        //   setDistrict("");
                        //   setDivision("");
                        // }}
                        className="btn"
                      >
                        clear filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
            }}
            className="row green "
          >
            {/*<h5>{categoryUrl}</h5>*?}
          {/*<h5>{label}</h5>*/}
          </div>
        </div>
        <div className="container">
          <div className="row">
            {result && result.length > 0 ? (
              result.map((item) => {
                const { images, title, price, id, district } = item.data;
                return (
                  <>
                    <ThumbNailSilders item={item} />
                  </>
                );
              })
            ) : (
              <>
                <p>no data</p>
                <h4>Try to filter buy you Distict</h4>
              </>
            )}
          </div>

          <div className="row">
            {loading ? (
              <>
                <div className="center">
                  <h3
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Loading..
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <img src={LaodingImg} alt="" />
                  </h3>
                </div>
              </>
            ) : collectionIsEmpty ? (
              <div>
                <h3 className="center"> Opps No more data</h3>
              </div>
            ) : (
              <>
                <p className="center">
                  <span
                    style={{
                      background: "coral",
                      padding: "10px",
                      color: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={LoadMoreHandeler}
                  >
                    Load More Ads
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BottomCategories;
