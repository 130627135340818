import React, { useEffect, useState } from "react";
import { db } from "../../lib/Firebase/firebase";
import { Link, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  deleteDoc,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";
import ThumbNailSilders from "../../components/ThumbNailSiders/ThumbNailSilders";
import Navbar from "../../components/Navbar/Navbar";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";

function SearchPage() {
  const { closeSubmenu } = useGlobalContext();
  const { term } = useParams();
  const searchRef = collection(db, "ads");
  const [searchTerm, setSearchTerm] = useState([]);
  const [laoding, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      searchRef,
      where("active", "==", true),
      where("title", ">=", term.toLowerCase().slice(0, 3)),
      where("title", "<=", term.toLowerCase().slice(0, 3) + "\uf8ff"),
      //where("description", ">=", term.toLowerCase()),
      // where("description", "<=", term.toLowerCase() + "\uf8ff"),
      limit(2)
    );
    // onSnapshot(q, (snapshoot) => {
    //   setSearchTerm(
    //     snapshoot.docs.map((doc) => ({
    //       id: doc.id,
    //       data: doc.data(),
    //     }))
    //   );
    // });

    let matchFound = false;
    let lastDoc = null;

    const handleSnapshot = (snapshot) => {
      // Check if there are any matching documents in this batch
      if (!snapshot.empty) {
        matchFound = true;
        onSnapshot(q, (snapshoot) => {
          setSearchTerm(
            snapshoot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
        console.log(snapshot.docs.map((doc) => doc.data()));
      } else {
        // If no matches found in this batch, check the next batch (if any)
        if (lastDoc) {
          q = query(
            searchRef,
            where("active", "==", true),
            where("title", ">=", term.toLowerCase().slice(0, 3)),
            where("title", "<=", term.toLowerCase().slice(0, 3) + "\uf8ff"),
            startAfter(lastDoc),
            limit(2)
          );
        }
        onSnapshot(q, handleSnapshot);
      }
    };

    onSnapshot(q, (snapshot) => {
      handleSnapshot(snapshot);
      lastDoc = snapshot.docs[snapshot.docs.length - 1];
    });
    setLoading(false);
  }, [term]);

  console.log(searchTerm);

  if (laoding) {
    return <div>loading</div>;
  } else {
    return (
      <div>
        <NavbarWrap />
        <div onMouseOver={closeSubmenu}>
          <div className="container">
            <div className="row">
              <div className="col l12">
                <div
                  style={{
                    marginTop: 30,
                  }}
                >
                  SearchPage
                  <h5>{term.slice(0, 3)}</h5>
                </div>
              </div>
            </div>
            <div className="row">
              {searchTerm.map((item) => {
                console.log(item);
                return <ThumbNailSilders item={item} key={item.id} />;
              })}
              {searchTerm
                .filter((value) => {
                  return (
                    value.data.title.includes(term) ||
                    value.data.description.includes(term)
                  );
                })
                .slice(0, 8)
                .map((item) => {
                  console.log(item);
                  return <ThumbNailSilders item={item} key={item.id} />;
                })}
            </div>
          </div>

          <div className="container">
            <div>
              <h5 className="center">Our Partners</h5>
            </div>
            <div className="row">
              <div className="col l6">
                <div
                  style={{
                    background: "#f3a6ff",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h3>Levbitz</h3>
                  <p>Online Elearning Platform</p>
                </div>
              </div>
              <div className="col l6">
                <div
                  style={{
                    background: "#f3a6ff",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h3>Levbitz</h3>
                  <p>Online Elearning Platform</p>
                </div>
              </div>
              <div className="col l6">
                <div
                  style={{
                    background: "#f3a6ff",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h3>Levbitz</h3>
                  <p>Online Elearning Platform</p>
                </div>
              </div>
              <div className="col l6">
                <div
                  style={{
                    background: "#f3a6ff",
                    padding: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h3>Levbitz</h3>
                  <p>Online Elearning Platform</p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginBottom: 50,
              height: 30,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default SearchPage;

//     background: linear-gradient(173deg,#000,#17080f 28%,#4f1c34 43%,#17080f 56%,#000 78%,#fff calc(78% + 1px));
// }
