import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./NavbarComponents/Sidebar/Siderbar";
import Submenu from "./NavbarComponents/SubMenu/SubMenu";

function NavbarWrap() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Submenu />
    </div>
  );
}

export default NavbarWrap;
