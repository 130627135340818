import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div
      style={{
        background: "#064757",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col l3"></div>
          <div className="col l6">
            <img
              className="responsive-img"
              src="https://layouts.levbitz.com/static/media/404.1bd1b2714edade2dd6cc.png"
              alt=""
            />
          </div>
          <div className="col l3"></div>

          <div className="row">
            <div className="col l12">
              <div>
                <h2 className="white-text center">
                  Sorry We Can't Find That Page!
                </h2>
                <h5 className="white-text center">
                  The page you are looking for was moved, removed, renamed or
                  never existed..
                </h5>
                <p className="center">
                  <Link
                    to="/"
                    className="btn btn-large waves-effect waves-light red z-depth-0"
                  >
                    Back to Home
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
