import React, { useState, useEffect } from "react";
import { auth, db } from "../../../../lib/Firebase/firebase";
//import Certification from "../../assets/profile/certification.png";
import { MdAutoDelete } from "react-icons/md";
import AdImg from "../../MyProfileAdComponents/AdImg/AdImg";
import LoadMoreAnime from "../../../../SubComponents/LoadMoreAnime/LoadMoreAnime";
import LoadMoreAdsButton from "../../../../SubComponents/LoadMoreAdsButton/LoadMoreAdsButton";

import {
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  orderBy,
  deleteDoc,
  doc,
  addDoc,
  limit,
  updateDoc,
  getDocs,
  startAfter,
} from "firebase/firestore";
import "../../MyAds.css";
import { BiTimer } from "react-icons/bi";

import { Link, useNavigate } from "react-router-dom";

function ActiveAds({ user }) {
  const [courses, setCourses] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);

  const [myAlert, setMyAlert] = useState();
  //console.log(user);
  const checckerRef = collection(db, "ads");
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setMyAlert(user.email);
      if (user) {
        const q = query(
          checckerRef,
          where("email", "==", user.email),
          where("active", "==", true),
          orderBy("createdAt", "desc"),
          limit(3)
        );

        onSnapshot(q, (snapshoot) => {
          if (snapshoot.size === 0) {
            alert("collection is empty");
            setLoading(false);
            setCollectionIsEmpty(true);
          } else {
            // console.log(snapshoot.docs);
            setLoading(true);
            // setTimeout(() => {

            const term = snapshoot.docs.map((doc) =>
              // console.log(doc.data())
              ({
                id: doc.id,
                data: doc.data(),
              })
            );

            setCourses(term);
            setLoading(false);
            //get the last doc
            setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
          }
          // setCourses(
          //   snapshoot.docs.map((doc) => ({
          //     id: doc.id,
          //     data: doc.data(),
          //   }))
          // );
        });
      } else {
        // navigate("/login");
      }
    });

    return () => {};
  }, []);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    setLoading(false);
    auth.onAuthStateChanged((user) => {
      setMyAlert(user.email);
      if (user) {
        const q = query(
          NewData,
          where("email", "==", user.email),
          where("active", "==", true),
          orderBy("createdAt", "desc"),
          limit(3),
          startAfter(lastDoc)
        );

        getDocs(q).then((snapshoot) => {
          if (snapshoot.size === 0) {
            setLoading(false);
            setCollectionIsEmpty(true);
          } else {
            const term = snapshoot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));

            setCourses([...courses, ...term]);
            setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
            setLoading(false);
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col l12 s12">
          <div>
            <h4
              style={{
                fontSize: 18,
              }}
              className=""
            >
              Your Ads
            </h4>

            <div>
              {courses.length === 0 ? (
                <div>
                  <div className="row ">
                    <div className="col l12">
                      <h5
                        style={{
                          fontSize: 16,
                        }}
                        className=""
                      >
                        You have no Ads now
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/*<img width={80} src={Certification} alt="" />*/}
                  {courses.map((cour) => {
                    return (
                      <div
                        style={{
                          opacity: cour.data.active === true ? 1 : 0.8,
                        }}
                        key={cour.id}
                        className="col l4 s12   "
                      >
                        <div
                          style={{
                            border: "1px solid #ccc",
                            margin: "2px 10px",
                            borderRadius: 15,
                          }}
                          className="row "
                        >
                          <div className="col l4 s3 ">
                            <AdImg
                              onClick={() => {
                                navigate(`/pad/${cour.id}`);
                              }}
                              src={cour.data.images[0]}
                            />
                          </div>
                          <div className="col l8 s8 ">
                            <div
                              style={{
                                marginBottom: "1rem",
                              }}
                            >
                              <h6
                                style={{
                                  fontSize: 14,
                                  color: "#004d40 ",
                                }}
                                className="my-ads__title truncate"
                                onClick={() => {
                                  navigate(`/pad/${cour.id}`);
                                }}
                              >
                                {cour.data.title}
                              </h6>

                              <small
                                style={{ display: "flex" }}
                                className="black-text"
                              >
                                <BiTimer size={18} style={{ marginRight: 5 }} />{" "}
                                Posted On
                                {cour.data.createdAt.toDate().toDateString()}
                              </small>
                            </div>

                            <div>
                              {cour.data.active === true ? (
                                <div className="row">
                                  <div className="col l2">
                                    <p>
                                      <MdAutoDelete
                                        color="red"
                                        size={20}
                                        onClick={async () => {
                                          await addDoc(
                                            collection(db, "deletedAds"),
                                            {
                                              deletedAt: serverTimestamp(),
                                              id: cour.id,
                                              deleted: false,
                                            }
                                          );
                                          await deleteDoc(
                                            doc(checckerRef, cour.id)
                                          );
                                          await window.location.reload();
                                        }}
                                      />
                                    </p>
                                  </div>
                                  <div className="col l10">
                                    <p>Add is active</p>
                                  </div>
                                  <small
                                    onClick={() => {
                                      window.confirm(
                                        "Are you sure you want to Deactivate  this ad?"
                                      ) &&
                                        updateDoc(doc(db, "ads", cour.id), {
                                          active: false,
                                          promotionType: "Free Ad",
                                        }).then(() => {
                                          alert(
                                            "Ad Deactivated successfully As Free Ad"
                                          );
                                          // window.location.reload();
                                          //navigate("/");
                                        });
                                    }}
                                    style={{
                                      backgroundColor: "#004d40",
                                      color: "#fff",
                                      padding: "0.1rem .5rem",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Deactivate Ad
                                  </small>

                                  <div className="row">
                                    <div className="col l10">
                                      <div
                                        style={{
                                          marginTop: 10,
                                        }}
                                        className="center"
                                      >
                                        <Link to={`/edit/${cour.id}`}>
                                          Edit
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col l2">
                                      <p>
                                        <p>
                                          <MdAutoDelete
                                            size={14}
                                            onClick={async () => {
                                              await addDoc(
                                                collection(db, "deletedAds"),
                                                {
                                                  deletedAt: serverTimestamp(),
                                                  id: cour.id,
                                                  deleted: false,
                                                }
                                              );
                                              await deleteDoc(
                                                doc(checckerRef, cour.id)
                                              );
                                              await window.location.reload();
                                            }}
                                          />
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {loading ? (
          <LoadMoreAnime />
        ) : collectionIsEmpty ? (
          <div>
            <h3 className="center"> Opps No more data</h3>
          </div>
        ) : (
          <LoadMoreAdsButton onClick={LoadMoreHandeler} />
        )}
      </div>
    </div>
  );
}

export default ActiveAds;
