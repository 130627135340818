import React, { useState, useCallback } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { auth, db } from "../../lib/Firebase/firebase";
import { updateDoc, doc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import "./ResetPassword.css";
import { GrHome } from "react-icons/gr";
//import useTitleHook from "../../hooks/useTitleHook/useTitleHook";
//import SpinnerOne from "../../SubComponents/Spinners/SpinnerOne/SpinnerOne";

const ResetPassword = () => {
  const { closeSubmenu } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();
  const isInvalid = email === "";

  const navigate = useNavigate();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const { email } = event.target.elements;
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email.value);
      setMessage("check your email to reset password");
      setLoading(false);
    } catch (error) {
      setMessage(`${error.message} `);
      setLoading(false);
      setEmail("");
    }
  }, []);
  return (
    <div onMouseMove={closeSubmenu}>
      <div className="loginPage_wrap">
        <div className="container">
          <div className="row">
            <div className="col l3"></div>
            <div className="col l6 s12">
              <div className="white registerForm_wrap">
                <div>
                  <h5 style={{ color: "#094c59", marginBottom: 20 }}>
                    Enter Email
                  </h5>
                </div>

                {message ? (
                  <>
                    <div
                      className="z-depth-3"
                      style={{
                        backgroundColor: "#094c59",
                        borderRadius: "10px",
                      }}
                    >
                      <h5
                        style={{
                          marginBottom: "30px",
                          padding: "20px 5px",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                        className=" center white-text"
                      >
                        {message}
                      </h5>
                    </div>
                  </>
                ) : null}

                <form method="POST" className="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined">
                        <label htmlFor="email">Email</label>

                        <input
                          className="validate"
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {error ? <p className="error red-text">{error}</p> : null}
                  <div className="row">
                    <div className=" col s12 ">
                      <button
                        className="col l12 s12  btn btn-large waves-effect indigo center"
                        disabled={isInvalid}
                      >
                        {loading ? "checking..." : "Reset Password"}
                      </button>
                    </div>
                  </div>

                  <div className="row ">
                    <div
                      style={{
                        padding: "10px 20px",
                        marginTop: "10px",
                      }}
                    >
                      <label className="left">
                        <Link className="pink-text" to="/sign_up">
                          <b>New User?Register Now</b>
                        </Link>
                      </label>
                      <label className=" right">
                        <Link className="pink-text" to="/sign_in">
                          <b>Login</b>
                        </Link>
                      </label>
                    </div>
                  </div>

                  <p className="center">
                    <Link to="/">
                      <GrHome size={25} />
                    </Link>
                  </p>
                </form>
              </div>
            </div>
            <div className="col l3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
