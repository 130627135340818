import React from "react";
import "./socialmedia.css";
import {
  BsFacebook,
  BsLinkedin,
  BsGithub,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

const SocialMedia = () => {
  return (
    <div className="lb_social">
      <h4 className="center">
        Get In touch with us on all our social media Platforms
      </h4>
      <div className="container center">
        <div className="row center">
          <div className="col l4"></div>
          <div className="col l1 s2 ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/mybantuMart/"
            >
              <BsFacebook size={30} color={"#fff"} />
            </a>
          </div>
          <div className="col  l1 s2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/bantumart/?viewAsMember=true"
            >
              <BsLinkedin size={30} />
            </a>
          </div>
          <div className="col  l1 s2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Levbitz"
            >
              <BsGithub size={30} color="#fff" />
            </a>
          </div>
          <div className="col  l1 s2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/abantuMart"
            >
              <BsTwitter size={30} />
            </a>
          </div>{" "}
          <div className="col  l1 s2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@ug3659"
            >
              <BsYoutube size={30} color="red" />
            </a>
          </div>
          <div className="col l4"></div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
