import React from "react";
import LaodingImg from "../../lib/images/loading.png";

function LoadMoreAnime() {
  return (
    <div className="center">
      <h3
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        Loading..
      </h3>
      <h3
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <img src={LaodingImg} alt="" />
      </h3>
    </div>
  );
}

export default LoadMoreAnime;
