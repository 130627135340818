import React, { useState } from "react";
import NavBarTwo from "../../Business/BusinessComponents/NavBarTwo/NavBarTwo";
import { useParams } from "react-router-dom";
import ReportImg from "../../lib/images/report.png";

function ReportAd() {
  const { id, email, phoneNumber, title } = useParams();
  console.log(useParams());

  const [report, setReport] = useState("");

  const invalid = report === "";
  return (
    <div
      style={{
        background: "#064757",
        paddingBottom: 100,
      }}
      className=""
    >
      <NavBarTwo />

      <div
        style={{
          paddingTop: 30,
        }}
      >
        <div className="row">
          <div
            style={{
              height: "auto",
            }}
            className="col l7 center-align  hide-on-med-and-down"
          >
            <div
              style={{
                paddingTop: "10%",
              }}
            >
              <img src={ReportImg} alt="" />
            </div>
          </div>
          <div className="col l4 s12 ">
            <div
              style={{
                padding: 10,
                borderRadius: 20,
                margin: 5,
              }}
              className="row white"
            >
              <div className="col l12 s12">
                <h3>Reporting Ad</h3>

                <p>{title}</p>
                <small className="right">ad id</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    style={{
                      padding: "0px 5px",
                    }}
                    value={id}
                    disabled
                    // onChange={(e) => setAddress(e.target.value)}
                    id="id"
                    type="text"
                  />
                  {/*<label htmlFor="your_address">Address</label>*/}
                </div>
              </div>

              <div className="col l12 s12">
                <small className="right">title</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    style={{
                      padding: "0px 5px",
                    }}
                    value={title}
                    disabled
                    // onChange={(e) => setAddress(e.target.value)}
                    id="title"
                    type="text"
                  />
                  {/*<label htmlFor="your_address">Address</label>*/}
                </div>
              </div>

              <div className="col l12 s12 hide">
                <small className="right">title</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    style={{
                      padding: "0px 5px",
                    }}
                    value={phoneNumber}
                    disabled
                    // onChange={(e) => setAddress(e.target.value)}
                    id="Phone"
                    type="text"
                  />
                  {/*<label htmlFor="your_address">Address</label>*/}
                </div>
              </div>
              <div className="col l12 s12 hide">
                <small className="right">email</small>
                <div className=" input-field input-outlined l6 ">
                  <input
                    style={{
                      padding: "0px 5px",
                    }}
                    value={email}
                    disabled
                    // onChange={(e) => setAddress(e.target.value)}
                    id="email"
                    type="text"
                  />
                  {/*<label htmlFor="your_address">Address</label>*/}
                </div>
              </div>

              <div className="col l12 s12">
                <small className="right"> * atleast 5 characters minimum</small>
                <div className=" input-field input-outlined l6 ">
                  <textarea
                    onChange={(e) => setReport(e.target.value)}
                    style={{
                      height: "80px",
                      background: "#fff",
                    }}
                    id="report"
                    type="text"
                    className="col  s12"
                  ></textarea>
                  <label htmlFor="report">Reason</label>
                </div>
              </div>
              <div className="col l12 s12">
                <div
                  style={{
                    marginTop: 20,
                  }}
                  className="center"
                >
                  <button disabled={invalid} className="btn red z-depth-0">
                    Submit qeury
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col l1"></div>
        </div>
      </div>
    </div>
  );
}

export default ReportAd;

// <div className="red">
//

//   <p>{title}</p>

// </div>;
